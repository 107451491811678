import React, { useState } from "react";
import { Button } from "../../shadcn/button";
import { Clipboard } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";

const ApiRevealButton = () => {
  const [revealed, setRevealed] = useState(false);
  const apiKey = localStorage.getItem("apikey");

  const handleClick = () => {
    if (!revealed) {
      setRevealed(true);
    } else {
      navigator.clipboard
        .writeText(apiKey)
        .then(() => {
          toast.success("API key copied to your clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error("Failed to copy API key");
        });
    }
  };

  const truncateApiKey = (key, visibleChars = 20) => {
    return key.slice(0, visibleChars) + "...";
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        className={`
            w-64 h-12 font-semibold
            transition-all duration-300 ease-in-out
            flex items-center px-4
            ${
              revealed
                ? "justify-between text-black bg-gray-200 hover:bg-gray-200/80"
                : "justify-center text-white bg-black/90 hover:bg-black/80"
            }
          `}
      >
        <span className={revealed ? "":"text-center w-full"}>
          {revealed ? truncateApiKey(apiKey) : "Click here to reveal API key"}
        </span>
        {revealed && <Clipboard className="ml-2 w-4 h-4" />}
      </Button>
    </div>
  );
};

export default ApiRevealButton;
