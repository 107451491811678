import { useEffect, useState, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import apiVisitors from "./VisitorsApis/apiVisitors";
import { Avatar, AvatarFallback, AvatarImage } from "../../shadcn/avatar";
import { Button } from "../../shadcn/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn/table";
import { Badge } from "../../shadcn/badge";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import Spinner from "../../ui/Spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/tooltip";
import { Card, CardContent } from "../../shadcn/card";
import { PiEyesFill } from "react-icons/pi";
import apiSlackAgentAction from "../../Utils/apiSlackAgentAction";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const calculateLastLogin = (lastLoginString) => {
  const lastLoginDate = new Date(lastLoginString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - lastLoginDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} s ago`;
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} m ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} h ago`;
  } else {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} d ago`;
  }
};

const flagemojiToPNG = (countryCode) => {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`}
      alt="flag"
    />
  );
};

function handleWatchLiveButton(visitorID) {
  apiSlackAgentAction("viewed", visitorID);
}

const NUMBER_OF_VISITORS_PER_PAGE = 50;

const VisitorsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    Number.parseInt(searchParams.get("page") || "1", 10)
  );
  const [tooltips, setTooltips] = useState({});
  const queryClient = useQueryClient();
  const scrollRef = useRef(null);

  const getFilters = () => {
    const filters = {};
    for (const [key, value] of searchParams.entries()) {
      if (key !== "page") {
        if (filters[key]) {
          filters[key].push(value);
        } else {
          filters[key] = [value];
        }
      }
    }
    return filters;
  };

  // Configure React Query to fetch data every 2 seconds
  const { data, isLoading, isFetching, isPreviousData, error } = useQuery(
    ["visitors", currentPage, searchParams.toString()],
    () => apiVisitors(currentPage, NUMBER_OF_VISITORS_PER_PAGE, getFilters()),
    {
      keepPreviousData: true,
      staleTime: 5000,
      refetchInterval: 5100,
      refetchIntervalInBackground: true,
    }
  );

  useEffect(() => {
    const page = Number.parseInt(searchParams.get("page") || "1", 10);
    setCurrentPage(page);
  }, [searchParams]);

  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(
      (data?.total_visitors || 0) / NUMBER_OF_VISITORS_PER_PAGE
    );
    if (newPage >= 1 && newPage <= totalPages) {
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set("page", newPage.toString());
        return params;
      });
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const totalPages = Math.ceil(
      (data?.total_visitors || 0) / NUMBER_OF_VISITORS_PER_PAGE
    );
    if (currentPage < totalPages) {
      queryClient.prefetchQuery(
        ["visitors", currentPage + 1, searchParams.toString()],
        () =>
          apiVisitors(
            currentPage + 1,
            NUMBER_OF_VISITORS_PER_PAGE,
            getFilters()
          )
      );
    }
  }, [currentPage, data?.total_visitors, queryClient, searchParams]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  if (isLoading) {
    return (
      <div className="mt-20">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const visitors = data?.visitors || [];
  const totalPages = Math.ceil(
    (data?.total_visitors || 0) / NUMBER_OF_VISITORS_PER_PAGE
  );

  return (
    <div className="relative py-2 w-full">
      <div ref={scrollRef}></div>
      {isFetching && !isLoading && (
        <div className="absolute top-0 right-0 m-4">
          <Spinner size="sm" />
        </div>
      )}
      <Table className="bg-transparent md:bg-white">
        <TableHeader className="hidden pb-4 md:table-row-group">
          <TableRow className="hover:bg-white">
            <TableHead className="w-[300px]">CUSTOMER</TableHead>
            <TableHead>LAST ACTIVE</TableHead>
            <TableHead>LOCATION </TableHead>
            <TableHead># SESSIONS</TableHead>
            <TableHead>FIRST VISIT </TableHead>
            <TableHead>DEVICE </TableHead>
            <TableHead>LAST PAGE </TableHead>
            <TableHead className="sticky -right-5 z-10 pl-0 bg-white">
              <div className=" border-l-4 h-[48px] border-gray-100 flex justify-center items-center">
                ACTIONS
              </div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array.isArray(visitors) && visitors.length > 0 ? (
            visitors.map((customer) => (
              <TableRow
                key={customer._id}
                className="grid grid-cols-1 p-[0.5rem] min-[330px]:p-4 mb-5 bg-white rounded-lg border-b-2 md:rounded-none md:bg-transparent md:table-row md:border-0 hover:bg-white md:hover:bg-gray-50"
              >
                <TableCell className="order-2 font-medium">
                  <div className="flex items-center md:space-x-4">
                    <TooltipProvider>
                      <Tooltip open={tooltips[customer._id]}>
                        <TooltipTrigger asChild>
                          <Avatar
                            className="hidden cursor-pointer md:block"
                            onMouseEnter={() =>
                              setTooltips((prev) => ({
                                ...prev,
                                [customer._id]: true,
                              }))
                            }
                            onMouseLeave={() =>
                              setTooltips((prev) => ({
                                ...prev,
                                [customer._id]: false,
                              }))
                            }
                            onClick={() =>
                              setTooltips((prev) => ({
                                ...prev,
                                [customer._id]: !prev[customer._id],
                              }))
                            }
                          >
                            {customer.statuses?.in_f2f_session ? (
                              <img
                                src={"/logo/AgentOnCallLogo.svg"}
                                alt="F2F Session"
                                className="w-full h-full"
                              />
                            ) : customer.statuses?.in_stalking_session ? (
                              <>
                                <AvatarImage
                                  src={customer?.avatarUrl}
                                  alt={customer.data?.name || "user avatar"}
                                />
                                <AvatarFallback>
                                  <PiEyesFill className="w-5 h-5" />
                                </AvatarFallback>
                              </>
                            ) : (
                              <>
                                <AvatarImage
                                  src={customer?.avatarUrl}
                                  alt={customer.data?.name || "user avatar"}
                                />
                                <AvatarFallback>
                                  {customer?.name
                                    ? customer?.name.charAt(0)
                                    : "X"}
                                </AvatarFallback>
                              </>
                            )}
                          </Avatar>
                        </TooltipTrigger>
                        {(customer.statuses?.in_f2f_session ||
                          customer.statuses?.in_stalking_session) && (
                          <TooltipContent>
                            {customer.statuses?.in_f2f_session
                              ? "Another agent is in a Call with this Visitor"
                              : "Another agent is Stalking this Visitor"}
                          </TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                    <div>
                      <div className="font-semibold">
                        {customer?.name || customer._id}
                      </div>
                      <div className="text-sm text-muted-foreground">
                        {customer.data?.email || customer.ip}
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="order-1 w-full md:w-auto">
                  <div className="flex justify-between md:w-[5rem] md:gap-2 md:flex-col md:justify-normal md:items-center">
                    <Badge
                      variant={
                        customer.online === false ? "secondary" : "default"
                      }
                      className="flex justify-center items-center px-0 w-20 text-base"
                    >
                      {customer.online ? "online" : "offline"}
                    </Badge>
                    <span className="text-sm text-muted-foreground">
                      {!customer.online &&
                        calculateLastLogin(customer.last_online)}
                    </span>
                  </div>
                </TableCell>

                <TableCell className="flex order-3 justify-between md:order-none md:hidden">
                  <Card className="overflow-hidden w-full max-w-md border-none shadow-none">
                    <CardContent className="p-0">
                      <div className="grid grid-cols-2 gap-5 text-sm">
                        <div>
                          <p className="text-muted-foreground">Country:</p>
                          <div className="flex gap-2">
                            <span className="font-semibold">
                              {customer.location.country}
                            </span>
                            {flagemojiToPNG(customer.location.country)}
                          </div>
                        </div>
                        <div>
                          <p className="text-muted-foreground">City:</p>
                          <p className="font-semibold">
                            {customer.location.city}
                          </p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">
                            # of sessions:
                          </p>
                          <p className="font-semibold">
                            {customer.total_browsing_sessions}
                          </p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">Device name:</p>
                          <p className="font-semibold">
                            {customer.device.name}
                          </p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">Device type:</p>
                          <p className="font-semibold">
                            {customer.device.type}
                          </p>
                        </div>
                        <div>
                          <p className="text-muted-foreground">Last login:</p>
                          <p className="font-semibold">
                            {formatDate(customer.created_at)}
                          </p>
                        </div>
                      </div>
                      <div className="mt-5">
                        <p className="text-muted-foreground">
                          Last page visited:
                        </p>
                        <p className="text-xs font-semibold break-all">
                          {customer.current_page}
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                </TableCell>

                <TableCell className="hidden md:table-cell">
                  <p className="flex gap-2 mb-2">
                    <span>{customer.location.country}</span>
                    {flagemojiToPNG(customer.location.country)}
                  </p>
                  <p>{customer.location.city}</p>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  <p>{customer.total_browsing_sessions}</p>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  <p>{formatDate(customer.created_at)}</p>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  <p>{customer.device.name}</p>
                  <p>{customer.device.type}</p>
                </TableCell>
                <TableCell className="hidden md:table-cell">
                  <p>{customer.current_page}</p>
                </TableCell>
                <TableCell className="sticky -right-5 z-10 order-4 p-0 m-0 w-full text-right bg-white md:w-auto">
                  <div className="flex justify-center items-center p-4 min-[768px]:border-l-4 min-[768px]:pr-[20px] min-[768px]:h-[115px] min-[768px]:border-l-gray-100 ">
                    <Link to={`/visitors/${customer._id}`}>
                      <Button
                        onClick={
                          customer.online
                            ? () => handleWatchLiveButton(customer._id)
                            : undefined
                        }
                        variant="outline"
                        size="sm"
                        className={`w-full ${
                          customer.online === true
                            ? "bg-green-500 hover:bg-green-600"
                            : "bg-black text-white hover:text-white hover:bg-gray-900"
                        }  md:w-28`}
                      >
                        {customer.online === true
                          ? "Watch Live"
                          : "View history"}
                      </Button>
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} className="text-center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex justify-end items-center py-4 space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1 || isPreviousData}
        >
          <FiChevronLeft className="w-4 h-4" />
        </Button>

        <p className="text-sm text-muted-foreground">
          {currentPage} of {totalPages}
        </p>

        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages || isPreviousData}
        >
          <FiChevronRight className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
};

export default VisitorsTable;
