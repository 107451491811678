import { useEffect, useRef, useState } from "react";
import "rrweb-player/dist/style.css";
import rrwebPlayer from "rrweb-player";
import apiSingleRecording from "./RecordingApis/apiSingleRecording";
import { useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { Clock, Info, Laptop, MapPin } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn/card";
import { ScrollArea } from "../../shadcn/scroll-area";
import { useNavigate } from "react-router-dom";
import SidebarOpenButton from "../../ui/SidebarOpenButton";

const formatDuration = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const parts = [];
  if (h > 0) parts.push(`${h}h`);
  if (m > 0) parts.push(`${m}m`);
  if (s > 0 || parts.length === 0) parts.push(`${s}s`);

  return parts.join(" ");
};

const SingleRecording = () => {
  const navigate = useNavigate();

  const playerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [consoleLogs, setConsoleLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, setRecordingData] = useState(null);
  const { id } = useParams();
  const [logFilters, setLogFilters] = useState({
    error: true,
    info: true,
    log: true,
  });

  useEffect(() => {
    setIsLoading(true);
    apiSingleRecording(id)
      .then((data) => {
        setRecordingData(data);
        console.log("recording data", data);
        // Get the start time from the first event
        const startTime = data.events[0]?.timestamp || 0;

        // Process console events
        const consoleEvents = data.events
          .filter(
            (event) =>
              event.type === 6 && event.data?.plugin === "rrweb/console@1"
          )
          .map((event) => {
            let content = "";
            try {
              const consoleData = event.data.payload;
              if (consoleData && Array.isArray(consoleData.payload)) {
                content = consoleData.payload
                  .map((item) => {
                    if (item === null) return "null";
                    if (item === undefined) return "undefined";
                    if (typeof item === "object") {
                      return JSON.stringify(item, null, 2);
                    }
                    return String(item);
                  })
                  .join(" ");
              }
            } catch (err) {
              console.error("Error processing console event:", err);
              content = "Error processing console content";
            }

            return {
              type: event.data.payload.level || "log",
              content,
              timestamp: event.timestamp,
              relativeTime: (event.timestamp - startTime) / 1000, // Store as number for formatting
            };
          });

        setEvents(data.events);
        setConsoleLogs(consoleEvents);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!events.length || !playerRef.current) return;

    const createPlayer = () => {
      // Clean up previous instance
      if (playerInstanceRef.current) {
        playerInstanceRef.current.pause();
        playerInstanceRef.current = null;
      }

      // Clear the container element
      playerRef.current.innerHTML = "";

      const containerWidth = playerRef.current.clientWidth;
      const containerHeight = Math.min(500, containerWidth * 0.5625); // 16:9 aspect ratio

      const player = new rrwebPlayer({
        target: playerRef.current,
        props: {
          events,
          width: containerWidth,
          height: containerHeight,
          skipInactive: true,
          showController: true,
          autoScale: true,
          scaling: 1,
        },
      });

      playerInstanceRef.current = player;
    };

    try {
      createPlayer();

      // Debounce the resize handler to prevent too many recreations
      let resizeTimeout;
      const handleResize = () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(createPlayer, 250); // Wait 250ms after resize ends
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        clearTimeout(resizeTimeout);
        if (playerInstanceRef.current) {
          playerInstanceRef.current.pause();
          playerInstanceRef.current = null;
        }
        if (playerRef.current) {
          playerRef.current.innerHTML = "";
        }
      };
    } catch (error) {
      console.error("Error creating player:", error);
    }
  }, [events]);

  const getLogColor = (type) => {
    switch (type) {
      case "error":
        return "text-red-500";
      case "warn":
        return "text-yellow-600";
      case "info":
        return "text-blue-500";
      default:
        return "text-gray-700";
    }
  };

  const getLogCounts = () => {
    return consoleLogs.reduce(
      (acc, log) => {
        acc[log.type] = (acc[log.type] || 0) + 1;
        return acc;
      },
      { error: 0, info: 0, log: 0 }
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading recording...
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <button
          className="min-[300px]:h-10 h-8 gap-2 min-[300px]:gap-4  rounded-3xl text-black bg-white border border-transparent hover:border-black active:border-black  flex items-center justify-center  p-2 min-[300px]:p-3.5 "
          onClick={() => navigate("/recordings")}
        >
          <IoArrowBack /> <span>Back</span>
        </button>

        <SidebarOpenButton />
      </div>
      <div className="pt-6 space-y-6">
        {/* Top section with recording and console */}
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {/* Recording player card */}
          <Card>
            <CardHeader>
              <CardTitle>Recording Playback</CardTitle>
              <CardDescription>
                Session replay for visitor {recordingData?.visitor_name}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div
                ref={playerRef}
                className="pb-6 w-full bg-white rounded"
                style={{
                  minHeight: "200px",
                  maxHeight: "500px",
                  height: "auto",
                }}
              />
            </CardContent>
          </Card>

          {/* Console output card */}
          <Card>
            <CardHeader>
              <CardTitle>Console Output</CardTitle>
              <CardDescription>
                Logs captured: {consoleLogs.length}
                <div className="flex flex-wrap gap-2 mt-2">
                  {Object.entries(getLogCounts()).map(([type, count]) => (
                    <button
                      key={type}
                      onClick={() =>
                        setLogFilters((prev) => ({
                          ...prev,
                          [type]: !prev[type],
                        }))
                      }
                      className={`px-3 py-1.5 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors
                        ${
                          logFilters[type]
                            ? type === "error"
                              ? "ring-2 ring-red-500"
                              : type === "info"
                              ? "ring-2 ring-blue-500"
                              : "ring-2 ring-gray-700"
                            : "ring-1 ring-transparent"
                        }
                        cursor-pointer`}
                    >
                      <span
                        className={`text-sm font-medium ${getLogColor(type)}`}
                      >
                        {type.charAt(0).toUpperCase() + type.slice(1)} ({count})
                      </span>
                    </button>
                  ))}
                </div>
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-[500px] w-full rounded-md border p-4">
                <div className="space-y-4">
                  {consoleLogs
                    .filter((log) => logFilters[log.type])
                    .map((log, index) => (
                      <div
                        key={index}
                        className={`font-mono ${getLogColor(
                          log.type
                        )} p-3 bg-muted rounded-md`}
                      >
                        <div className="flex gap-2 mb-1 text-sm text-muted-foreground">
                          <span>
                            {new Date(log.timestamp).toLocaleTimeString()}
                          </span>
                          <span className="text-blue-500">
                            +{formatDuration(log.relativeTime)}
                          </span>
                        </div>
                        <div>
                          <span className="mr-2 font-semibold">
                            [{log.type}]
                          </span>
                          <pre className="overflow-x-auto mt-1 text-xs whitespace-pre-wrap">
                            {log.content}
                          </pre>
                        </div>
                      </div>
                    ))}
                  {consoleLogs.length === 0 && (
                    <div className="p-3 italic text-muted-foreground">
                      No console logs available
                    </div>
                  )}
                  {consoleLogs.length > 0 &&
                    consoleLogs.filter((log) => logFilters[log.type]).length ===
                      0 && (
                      <div className="p-3 italic text-muted-foreground">
                        No logs match the selected filters
                      </div>
                    )}
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </div>

        {/* Bottom section with visitor details */}
        <Card>
          <CardHeader>
            <CardTitle>Visitor Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
              <div className="flex items-center p-4 space-x-2 rounded-lg bg-muted">
                <Info className="w-5 h-5 text-muted-foreground" />
                <div>
                  <p className="font-medium">Visitor</p>
                  <p className="text-sm text-muted-foreground">
                    {recordingData?.visitor_name} (ID:{" "}
                    {recordingData?.visitor_id})
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 space-x-2 rounded-lg bg-muted">
                <Laptop className="w-5 h-5 text-muted-foreground" />
                <div>
                  <p className="font-medium">Device & Browser</p>
                  <p className="text-sm text-muted-foreground">
                    {recordingData?.device.name} ({recordingData?.device.os}{" "}
                    {recordingData?.device.os_version}) -{" "}
                    {recordingData?.browser.name}{" "}
                    {recordingData?.browser.version}
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 space-x-2 rounded-lg bg-muted">
                <MapPin className="w-5 h-5 text-muted-foreground" />
                <div>
                  <p className="font-medium">Location</p>
                  <p className="text-sm text-muted-foreground">
                    {recordingData?.location.city},{" "}
                    {recordingData?.location.region},{" "}
                    {recordingData?.location.country}
                  </p>
                </div>
              </div>

              <div className="flex items-center p-4 space-x-2 rounded-lg bg-muted">
                <Clock className="w-5 h-5 text-muted-foreground" />
                <div>
                  <p className="font-medium">Session Info</p>
                  <p className="text-sm text-muted-foreground">
                    Created:{" "}
                    {new Date(recordingData?.created_at).toLocaleString()}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    IP: {recordingData?.ip}
                  </p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default SingleRecording;
