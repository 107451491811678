import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent } from "../../../shadcn/card";
import { Label } from "../../../shadcn/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../shadcn/select";
import { Textarea } from "../../../shadcn/textarea";
import { Input } from "../../../shadcn/input";
import { FaChevronDown } from "react-icons/fa";
import apiWorkflowsFields from "../WorkflowApis/apiWorkflowsFields";
import apiTeamsData from "../WorkflowApis/apiTeamsData";
import { DropDownWithSearch } from "../DropDownWithSearch";

const defaultMessage1 = `🚨 New Visitor 🚨:
{{visitor.name}}
Just opened {{visitor.current_page}}
Visited: {{visitor.total_browsing_sessions}} times
📍{{visitor.location.city}} :flag-{{visitor.location.country}}: - {{visitor.ip}} - {{visitor.browser.name}}, {{visitor.device.name}}, {{visitor.device.type}}`;

const defaultMessage2 = "☎️ {{visitor.name}} is calling 👋";

export default function StepTeams({ workflowData, setWorkflowData }) {
  const isPopup = workflowData.action.type === "popup";
  const [workflowFields, setWorkflowFields] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [customMessage, setCustomMessage] = useState(() => {
    if (workflowData.action.notification?.message) {
      return workflowData.action.notification.message;
    } else if (workflowData.action.message) {
      return workflowData.action.message;
    } else {
      const defaultMessage =
        workflowData.action.type === "popup"
          ? defaultMessage2
          : defaultMessage1;
      // Apply the default message to the workflow data
      if (isPopup) {
        setWorkflowData((prevData) => ({
          ...prevData,
          action: {
            ...prevData.action,
            notification: {
              ...prevData.action.notification,
              message: defaultMessage,
            },
          },
        }));
      } else {
        setWorkflowData((prevData) => ({
          ...prevData,
          action: {
            ...prevData.action,
            message: defaultMessage,
          },
        }));
      }
      return defaultMessage;
    }
  });
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef(null);

  useEffect(() => {
    const fetchWorkflowFields = async () => {
      try {
        const data = await apiWorkflowsFields();
        setWorkflowFields({
          ...Object.fromEntries(
            Object.entries(data.fields).map(([key, value]) => [
              `visitor.${key}`,
              value,
            ])
          ),
        });
        console.log("Workflow fields:", data);
      } catch (error) {
        console.error("Error fetching workflow fields:", error);
      }
    };

    fetchWorkflowFields();

    if (isPopup) {
      if (workflowData.action?.notification?.channel_id) {
        const initialItem = workflowData.action?.notification?.channel_id;
        setSelectedChannel(initialItem);
      }
    } else {
      if (workflowData.action.channel_id) {
        const initialItem = workflowData.action.channel_id;
        setSelectedChannel(initialItem);
      }
    }
  }, [
    workflowData.action.channel_id,
    workflowData.action?.notification?.channel_id,
  ]);

  useEffect(() => {
    function handleChannelChanging() {
      if (isPopup) {
        setWorkflowData((prevData) => ({
          ...prevData,
          action: {
            ...prevData.action,
            notification: {
              ...prevData.action.notification,
              channel_id: selectedChannel,
            },
          },
        }));
      } else {
        setWorkflowData((prevData) => ({
          ...prevData,
          action: {
            ...prevData.action,
            channel_id: selectedChannel,
          },
        }));
      }
    }

    handleChannelChanging();
  }, [selectedChannel]);

  // ovo dole je za suggested message kada kucamo {{

  const handleInputChange = (e) => {
    const value = e.target.value;
    const newCursorPosition = e.target.selectionStart;
    setCustomMessage(value);
    setCursorPosition(newCursorPosition);

    if (isPopup) {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          notification: { ...prevData.action.notification, message: value },
        },
      }));
    } else {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          message: value,
        },
      }));
    }

    const lastTwoChars = value.slice(newCursorPosition - 2, newCursorPosition);
    const textAfterCursor = value.slice(newCursorPosition);
    const hasClosingBrackets = textAfterCursor.includes("}}");

    if (lastTwoChars === "{{" && !hasClosingBrackets) {
      setSuggestions(Object.keys(workflowFields || {}));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const beforeCursor = customMessage.slice(0, cursorPosition);
    const afterCursor = customMessage.slice(cursorPosition);
    const lastOpenBrackets = beforeCursor.lastIndexOf("{{");
    const newValue =
      beforeCursor.slice(0, lastOpenBrackets) +
      "{{" +
      suggestion +
      "}}" +
      afterCursor;
    setCustomMessage(newValue);
    setShowSuggestions(false);
    textareaRef.current.focus();

    if (isPopup) {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          notification: {
            ...prevData.action.notification,
            message: newValue,
          },
        },
      }));
    } else {
      setWorkflowData((prevData) => ({
        ...prevData,
        action: {
          ...prevData.action,
          message: newValue,
        },
      }));
    }

    const newCursorPosition = lastOpenBrackets + suggestion.length + 4;
    setTimeout(() => {
      textareaRef.current.setSelectionRange(
        newCursorPosition,
        newCursorPosition
      );
    }, 0);
  };

  const handleSelectSuggestion = (suggestion) => {
    handleSuggestionClick(suggestion);
  };

  return (
    <Card className="border-none shadow-none">
      <CardContent className="p-0">
        <h2 className="mb-4 text-lg font-semibold">
          Configure your Teams Message
        </h2>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="message-source">
              Where do you want to get the message?
            </Label>
            <div className="flex relative items-center w-full rounded-md border focus-within:ring-1 focus-within:ring-primary focus-within:border-primary">
              <input
                placeholder="Enter your Teams Webhook URL"
                className="p-2 w-full text-sm rounded-md text-muted-foreground focus:outline-none"
                value={selectedChannel}
                onChange={(e) => setSelectedChannel(e.target.value)}
              />
              <a
                className="pr-4 rounded-full text-muted-foreground hover:text-foreground"
                href="https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet"
                target="_blank"
                rel="noopener noreferrer"
              >
                ?
              </a>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="custom-message">
              Configure your custom message
            </Label>
            <div className="relative">
              <Textarea
                id="custom-message"
                ref={textareaRef}
                value={customMessage}
                onChange={handleInputChange}
                placeholder="Type {{ to insert dynamic fields"
                className="w-full min-h-[150px] max-h-[150px]"
              />
              {showSuggestions && suggestions.length > 0 && (
                <div className="absolute right-0 left-0">
                  <DropDownWithSearch
                    suggestions={suggestions}
                    onSelect={handleSelectSuggestion}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
