import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Button } from "../../../shadcn/button";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../../shadcn/select";
import { Input } from "../../../shadcn/input";
import { Calendar } from "../../../shadcn/calendar";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../shadcn/dialog";
import { AiOutlineClose } from "react-icons/ai";
import Spinner from "../../../ui/Spinner";
import { Step3SearchDropDownList } from "./Step3SearchDropDownList";
const Step3 = forwardRef(
  (
    { workflowData, setWorkflowData, workflowsFields, onSkip, isEditMode },
    ref
  ) => {
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add this state

    const [collectedData, setCollectedData] = useState({
      conditions: {
        conditions: [],
        logic: "and",
      },
    });

    const operators = [
      "<",
      ">",
      "=<",
      ">=",
      "=",
      "!=",
      "contains",
      "does not contain",
      "exists",
      "does not exist",
    ];

    const addRow = (groupId, isOr = false) => {
      setGroups((prevGroups) => {
        if (isOr) {
          return [
            ...prevGroups,
            {
              id: Date.now(),
              rows: [
                {
                  id: Date.now() + 1,
                  field: "",
                  operator: ">",
                  value: "",
                },
              ],
            },
          ];
        } else {
          return prevGroups.map((group) =>
            group.id === groupId
              ? {
                  ...group,
                  rows: [
                    ...group.rows,
                    {
                      id: Date.now(),
                      field: "",
                      operator: ">",
                      value: "",
                    },
                  ],
                }
              : group
          );
        }
      });
    };

    const updateField = (groupId, rowId, newField) => {
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                rows: group.rows.map((row) =>
                  row.id === rowId
                    ? { ...row, field: newField, value: "" }
                    : row
                ),
              }
            : group
        )
      );
    };

    const updateOperator = (groupId, rowId, newOperator) => {
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                rows: group.rows.map((row) =>
                  row.id === rowId ? { ...row, operator: newOperator } : row
                ),
              }
            : group
        )
      );
    };

    const updateValue = (groupId, rowId, newValue) => {
      setGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                rows: group.rows.map((row) =>
                  row.id === rowId ? { ...row, value: newValue } : row
                ),
              }
            : group
        )
      );
    };

    const deleteRow = (groupId, rowId) => {
      setGroups((prevGroups) => {
        const updatedGroups = prevGroups
          .map((group) =>
            group.id === groupId
              ? { ...group, rows: group.rows.filter((row) => row.id !== rowId) }
              : group
          )
          .filter((group) => group.rows.length > 0);

        // If all groups are empty or the last row in the last group was deleted
        if (
          updatedGroups.length === 0 ||
          (updatedGroups.length === 1 && updatedGroups[0].rows.length === 0)
        ) {
          return [
            {
              id: Date.now(),
              rows: [
                {
                  id: Date.now() + 1,
                  field: "",
                  operator: "exists",
                  value: "",
                },
              ],
            },
          ];
        }

        return updatedGroups;
      });
    };

    const collectData = () => {
      const validGroups = groups.filter((group) =>
        group.rows.some(
          (row) =>
            row.field &&
            (row.operator === "exists" ||
              row.operator === "does not exist" ||
              row.value)
        )
      );

      if (validGroups.length === 0) {
        setCollectedData({ conditions: {} });
      } else if (validGroups.length === 1) {
        const validRows = validGroups[0].rows.filter(
          (row) =>
            row.field &&
            (row.operator === "exists" ||
              row.operator === "does not exist" ||
              row.value)
        );
        setCollectedData({
          conditions: {
            conditions: validRows.map((row) => ({
              field: row.field,
              operator: row.operator,
              value: row.value,
            })),
            logic: "and",
          },
        });
      } else {
        const collectedConditions = validGroups.map((group) => ({
          conditions: group.rows
            .filter(
              (row) =>
                row.field &&
                (row.operator === "exists" ||
                  row.operator === "does not exist" ||
                  row.value)
            )
            .map((row) => ({
              field: row.field,
              operator: row.operator,
              value: row.value,
            })),
          logic: "and",
        }));

        setCollectedData({
          conditions: {
            conditions: collectedConditions,
            logic: "or",
          },
        });
      }
    };

    useEffect(() => {
      collectData();
    }, [groups]);

    useEffect(() => {
      setWorkflowData((prev) => ({
        ...prev,
        conditions: collectedData.conditions,
      }));
    }, [collectedData, setWorkflowData]);

    const truncateValue = (value) => {
      return value.length > 3 ? value.substring(0, 3) + "..." : value;
    };

    const renderRows = (group, groupIndex, isLastGroup) => (
      <div key={group.id} className={groupIndex > 0 ? "mt-2 pt-2" : ""}>
        {groupIndex > 0 && (
          <div className="flex items-center mb-2">
            <span className="pr-4 text-sm text-gray-500">or</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
        )}
        <div className="w-full">
          {group.rows.map((row, rowIndex) => (
            <div key={row.id} className="flex items-center h-12">
              <div className="w-12 text-sm text-gray-500">
                {rowIndex > 0 ? "and " : ""}
              </div>
              <Step3SearchDropDownList
                value={row.field}
                onValueChange={(value) => updateField(group.id, row.id, value)}
                options={workflowsFields}
              />
              <div className="w-20">
                <Select
                  value={row.operator}
                  onValueChange={(value) =>
                    updateOperator(group.id, row.id, value)
                  }
                >
                  <SelectTrigger className="justify-center w-20 h-10 rounded-md border select-no-arrow">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {operators.map((op) => (
                      <SelectItem key={op} value={op}>
                        {op}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="pl-2 w-16">
                {workflowsFields[row.field] === "bool" ? (
                  <Select
                    value={row.value}
                    onValueChange={(value) =>
                      updateValue(group.id, row.id, value)
                    }
                  >
                    <SelectTrigger className="w-16">
                      <SelectValue placeholder="Value" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="true">true</SelectItem>
                      <SelectItem value="false">false</SelectItem>
                    </SelectContent>
                  </Select>
                ) : workflowsFields[row.field] === "date" ? (
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="outline" className="w-16">
                        {row.value ? truncateValue(row.value) : "Date"}
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                        <DialogTitle>Select Date and Time</DialogTitle>
                      </DialogHeader>
                      <div className="grid gap-4 py-4">
                        <Calendar
                          mode="single"
                          selected={
                            row.value
                              ? new Date(row.value.split("T")[0])
                              : undefined
                          }
                          onSelect={(date) => {
                            if (date) {
                              const currentValue = row.value
                                ? new Date(row.value)
                                : new Date();
                              date.setHours(
                                currentValue.getHours(),
                                currentValue.getMinutes(),
                                currentValue.getSeconds()
                              );
                              updateValue(group.id, row.id, date.toISOString());
                            }
                          }}
                          initialFocus
                        />
                        <Input
                          type="time"
                          step="1"
                          value={
                            row.value
                              ? new Date(row.value).toTimeString().slice(0, 8)
                              : ""
                          }
                          onChange={(e) => {
                            const [hours, minutes, seconds] =
                              e.target.value.split(":");
                            const newDate = row.value
                              ? new Date(row.value)
                              : new Date();
                            newDate.setHours(
                              Number(hours),
                              Number(minutes),
                              Number(seconds)
                            );
                            updateValue(
                              group.id,
                              row.id,
                              newDate.toISOString()
                            );
                          }}
                          placeholder="HH:MM:SS"
                        />
                      </div>
                    </DialogContent>
                  </Dialog>
                ) : (
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="outline" className="w-16">
                        {row.value ? truncateValue(row.value) : "Value"}
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                        <DialogTitle>Input the value</DialogTitle>
                      </DialogHeader>
                      <Input
                        id="value"
                        value={row.value}
                        onChange={(e) =>
                          updateValue(group.id, row.id, e.target.value)
                        }
                        className="w-full"
                        placeholder={
                          row.field ? workflowsFields[row.field] : "Value"
                        }
                      />
                    </DialogContent>
                  </Dialog>
                )}
              </div>
              <div className="pl-2 w-8">
                <Button
                  variant="ghost"
                  size="sm"
                  className="p-0 w-8 h-8"
                  onClick={() => deleteRow(group.id, row.id)}
                >
                  <AiOutlineClose className="w-4 h-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-2 mt-2 ml-8">
          <Button
            onClick={() => addRow(group.id)}
            variant="outline"
            size="sm"
            className="w-14 text-gray-700"
          >
            + and
          </Button>
          {isLastGroup && (
            <Button
              onClick={() => addRow(group.id, true)}
              variant="outline"
              size="sm"
              className="w-14 text-gray-700"
            >
              + or
            </Button>
          )}
        </div>
      </div>
    );

    const handleSkipOrClear = () => {
      setGroups([
        {
          id: Date.now(),
          rows: [{ id: Date.now() + 1, field: "", operator: ">", value: "" }],
        },
      ]);
      onSkip();
    };

    const validateStep = () => {
      const validGroups = groups.filter((group) =>
        group.rows.some(
          (row) =>
            row.field &&
            (row.operator === "exists" ||
              row.operator === "does not exist" ||
              row.value)
        )
      );

      return (
        validGroups.length > 0 ||
        groups.length === 0 ||
        (groups.length === 1 &&
          groups[0].rows.length === 1 &&
          !groups[0].rows[0].field &&
          !groups[0].rows[0].value)
      );
    };

    useImperativeHandle(ref, () => ({
      validateStep,
    }));

    useEffect(() => {
      setIsLoading(true);
      if (
        workflowData.conditions &&
        workflowData.conditions.conditions &&
        groups.length === 0
      ) {
        const existingConditions = workflowData.conditions.conditions;
        let newGroups;
        if (
          Array.isArray(existingConditions) &&
          existingConditions.length > 0 &&
          existingConditions[0].conditions
        ) {
          // Multiple groups (OR condition)
          newGroups = existingConditions.map((group, index) => ({
            id: Date.now() + index,
            rows: group.conditions.map((condition, rowIndex) => ({
              id: Date.now() + index + rowIndex,
              field: condition.field,
              operator: condition.operator,
              value: condition.value,
            })),
          }));
        } else if (existingConditions.length > 0) {
          // Single group (AND condition)
          newGroups = [
            {
              id: Date.now(),
              rows: existingConditions.map((condition, index) => ({
                id: Date.now() + index,
                field: condition.field,
                operator: condition.operator,
                value: condition.value,
              })),
            },
          ];
        } else {
          // No conditions or edit mode with no conditions
          newGroups = [
            {
              id: Date.now(),
              rows: [
                { id: Date.now(), field: "", operator: "exists", value: "" },
              ],
            },
          ];
        }
        setGroups(newGroups);
      }
      setIsLoading(false);
    }, [workflowData.conditions, groups.length, isEditMode]);

    return (
      <div className="space-y-4">
        <h2 className="text-lg font-semibold">
          Which filters should this workflow trigger on?
        </h2>
        <p className="text-sm text-gray-500">
          Only notify me for users that match the following criteria
        </p>
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <Spinner />
          </div>
        ) : (
          <div className="p-4 rounded-md border max-h-[350px] overflow-y-auto scrollbar-gutter-stable">
            <div className="pr-4">
              {groups.map((group, index) =>
                renderRows(group, index, index === groups.length - 1)
              )}
            </div>
          </div>
        )}
        <div className="flex justify-end">
          <Button variant="outline" onClick={handleSkipOrClear}>
            {isEditMode ? "Clear All" : "Skip"}
          </Button>
        </div>
      </div>
    );
  }
);

export default Step3;
