import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../shadcn/card";
import { Button } from "../../shadcn/button";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import toast from "react-hot-toast";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/select";
import apiVisitorFilters from "./VisitorsApis/apiVisitorFilters";
import { useSearchParams } from "react-router-dom";

export default function VisitorsFiltersCard({ onClose }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({});
  const [listOfFilters, setListOfFilters] = useState({});
  const [rows, setRows] = useState([
    { selectedKey: null, selectedValue: null },
  ]);

  // Parse filters from the URL on component mount
  useEffect(() => {
    const urlFilters = {};

    // Parse query parameters, handling duplicate keys and skipping "page"
    for (const [key, value] of searchParams.entries()) {
      if (key === "page") continue; // Skip the "page" parameter

      if (urlFilters[key]) {
        // Add additional values to the existing array
        urlFilters[key].push(value);
      } else {
        // Initialize a new array for the key
        urlFilters[key] = [value];
      }
    }

    setFilters(urlFilters);

    // Populate rows from URL filters
    const initialRows = Object.entries(urlFilters).flatMap(([key, values]) =>
      values.map((value) => ({
        selectedKey: key,
        selectedValue: value,
      }))
    );

    if (initialRows.length > 0) {
      setRows(initialRows);
    }
  }, [searchParams]);

  // Fetch available filters
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const data = await apiVisitorFilters();

        setListOfFilters(data.filters); // Assume data.filters is an object
      } catch (error) {
        console.error(error);
      }
    };

    fetchFilters();
  }, []);

  // Handler to update a row's selectedKey or selectedValue
  const handleRowChange = (index, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [field]: value } : row
      )
    );
  };

  // Handler to add a new row
  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { selectedKey: null, selectedValue: null },
    ]);
  };

  // Handler to remove a row
  const handleRemoveRow = (index) => {
    setRows((prevRows) => prevRows.filter((_, rowIndex) => rowIndex !== index));
  };

  // Handler for submit
  const handleSubmit = () => {
    const result = rows.reduce((acc, row) => {
      if (row.selectedKey && row.selectedValue) {
        acc[row.selectedKey] = acc[row.selectedKey]
          ? [...acc[row.selectedKey], row.selectedValue]
          : [row.selectedValue];
      }
      return acc;
    }, {});

    if (Object.keys(result).length === 0) {
      // No filters to submit; consider this "clear filters."
      toast.success("All filters cleared!");
      setFilters({}); // Clear filters
      setSearchParams({}); // Clear URL params
      onClose(); // Close the dialog
      return;
    }

    setFilters(result);

    const params = new URLSearchParams();

    Object.entries(result).forEach(([key, value]) => {
      // Append each array item separately to avoid extra encoding
      if (Array.isArray(value)) {
        value.forEach((item) => params.append(key, item));
      } else {
        params.append(key, value);
      }
    });

    setSearchParams(params);
    onClose();
    toast.success("Filters submitted successfully!");
  };

  return (
    <Card className="mx-auto w-full max-w-2xl rounded-lg border-0 shadow-none max-h-[80vh] overflow-y-auto">
      <CardHeader className="pb-4">
        <CardTitle className="text-xl">Visitor Filters</CardTitle>
      </CardHeader>
      <CardContent className="pb-2 space-y-4">
        {rows.map((row, index) => (
          <div
            key={index}
            className="flex flex-col items-start space-y-2 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2"
          >
            {/* First Select: Keys */}
            <div className="flex-1 w-full sm:w-auto">
              <Select
                value={row.selectedKey || undefined}
                onValueChange={(value) =>
                  handleRowChange(index, "selectedKey", value)
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a filter key" />
                </SelectTrigger>
                <SelectContent
                  className="overflow-y-auto max-h-60 max-w-60"
                  onWheel={(e) => e.stopPropagation()}
                >
                  <SelectGroup className="overflow-y-auto max-h-60">
                    <SelectLabel>Filter Keys</SelectLabel>
                    {Object.keys(listOfFilters).map((key) => (
                      <SelectItem key={key} value={key}>
                        {key}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <span className="hidden px-2 text-2xl font-bold sm:inline">=</span>

            {/* Second Select: Values */}
            <div className="flex-1 w-full sm:w-auto">
              <Select
                disabled={!row.selectedKey}
                value={row.selectedValue || undefined}
                onValueChange={(value) =>
                  handleRowChange(index, "selectedValue", value)
                }
              >
                <SelectTrigger className="w-full sm:max-w-[180px]">
                  <SelectValue
                    placeholder="Select a value"
                    className="truncate"
                  />
                </SelectTrigger>
                <SelectContent
                  className="overflow-y-auto max-h-60 max-w-60"
                  onWheel={(e) => e.stopPropagation()}
                >
                  <SelectGroup className="overflow-y-auto max-h-60">
                    <SelectLabel>Values</SelectLabel>
                    {row.selectedKey &&
                      (row.selectedKey === "online"
                        ? ["true", "false"].map((value) => (
                            <SelectItem
                              key={value}
                              value={value}
                              className="pr-6 whitespace-normal break-words"
                            >
                              {value}
                            </SelectItem>
                          ))
                        : listOfFilters[row.selectedKey]?.map((value, idx) => (
                            <SelectItem
                              key={idx}
                              value={value}
                              className="pr-6 whitespace-normal break-words"
                            >
                              {value}
                            </SelectItem>
                          )))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            {/* Remove Row Button */}
            <Button
              variant="ghost"
              onClick={() => handleRemoveRow(index)}
              className="mt-2 w-full text-red-500 hover:text-red-700 sm:mt-0 sm:w-auto"
            >
              <AiOutlineClose className="mr-2 w-4 h-4 sm:mr-0" />
              <span className="sm:hidden">Remove filter</span>
            </Button>
          </div>
        ))}
        <Button
          variant="outline"
          className="mt-4 w-full"
          onClick={handleAddRow}
        >
          <AiOutlinePlus className="mr-2 w-4 h-4" /> Add Filter
        </Button>
      </CardContent>
      <CardFooter className="pt-6">
        <Button className="w-full" onClick={handleSubmit}>
          {rows.length === 0 ? "Clear Filters" : "Apply Filters"}
        </Button>
      </CardFooter>
    </Card>
  );
}
