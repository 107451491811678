import React from "react";
import { FiMoreHorizontal, FiCopy, FiTrash } from "react-icons/fi";
import { Button } from "../shadcn/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn/table";
import apiDeleteMember from "./apiDeleteMember";
import toast from "react-hot-toast";
import apiOrganization from "../modules/Dashborad/apiOrganization";
import apiDeclineInvite from "../Signup/apiDeclineInvite";

export default function TeammatesTable({ members, setMembers }) {
  const handleCopyId = (userId) => {
    navigator.clipboard.writeText(userId);
    // You might want to add a toast notification here to inform the user that the ID has been copied
  };

  const handleDelete = async (userId) => {
    try {
      await apiDeleteMember(userId);
      setMembers();
    } catch (error) {
      console.error("Error deleting member:", error);
    } finally {
      toast.success("Member deleted successfully");
    }
  };

  const handleDeclineInvite = async (linkToDelete) => {
    const idToDelete = linkToDelete.split("/").pop();
    try {
      await apiDeclineInvite(idToDelete);
      setMembers((prevMembers) =>
        prevMembers.filter((item) => item.inviteID !== linkToDelete)
      );
      toast.success("Invitation revoked successfully!");
    } catch (error) {
      console.error(
        "Error revoking invitation (user already accepted or declined):",
        error
      );
    }
  };

  return (
    <div className="overflow-auto w-full rounded-lg">
      <Table className="bg-white rounded-lg">
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Email</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {members.map((teammate) => (
            <TableRow key={teammate.user_id}>
              <TableCell>{teammate.name}</TableCell>
              <TableCell>{teammate.role}</TableCell>
              <TableCell>{teammate.email}</TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="p-0 w-8 h-8">
                      <span className="sr-only">Open menu</span>
                      <FiMoreHorizontal className="w-4 h-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem
                      onClick={() =>
                        handleCopyId(
                          teammate.role === "invited"
                            ? teammate.inviteId
                            : teammate.user_id
                        )
                      }
                    >
                      <FiCopy className="mr-2 w-4 h-4" />
                      {teammate.role === "invited"
                        ? "Copy invite link"
                        : "Copy ID"}
                    </DropdownMenuItem>
                    {teammate.role !== "owner" && teammate.role !== "admin" && (
                      <DropdownMenuItem
                        onClick={() =>
                          teammate.role === "invited"
                            ? handleDeclineInvite(teammate.inviteId)
                            : handleDelete(teammate.user_id)
                        }
                      >
                        <FiTrash className="mr-2 w-4 h-4" />
                        {teammate.role === "invited"
                          ? "Revoke Invite"
                          : "Delete"}
                      </DropdownMenuItem>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
