import { Phone, PhoneOff, MapPin } from "lucide-react";
import { Card, CardContent, CardFooter } from "../shadcn/card";
import { Button } from "../shadcn/button";
import { Badge } from "../shadcn/badge";

const flagemojiToPNG = (countryCode) => {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`}
      alt="flag"
    />
  );
};

export function IncomingCallNotification({ visitor, acceptCall, declineCall }) {
  return (
    <Card className="w-80 shadow-lg duration-300 animate-in slide-in-from-top-5">
      <CardContent className="pt-4">
        <div className="space-y-4">
          <h3 className="text-lg font-semibold leading-none">{`${visitor.name} is calling you!`}</h3>
          <div className="flex items-center text-sm text-muted-foreground">
            <MapPin className="mr-1 w-3 h-3" />
            <Badge variant="secondary" className="flex gap-1 font-normal">
              {`${visitor.location.city} ,  `}
              {flagemojiToPNG(visitor.location.country)}
            </Badge>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between pt-2 pb-4">
        <Button
          variant="outline"
          size="sm"
          className="w-[48%] border-red-500 hover:bg-red-500 hover:text-white transition-colors"
          onClick={declineCall}
        >
          <PhoneOff className="mr-2 w-4 h-4" />
          Decline
        </Button>
        <Button
          size="sm"
          className="w-[48%] bg-green-500 hover:bg-green-600 transition-colors"
          onClick={acceptCall}
        >
          <Phone className="mr-2 w-4 h-4" />
          Accept
        </Button>
      </CardFooter>
    </Card>
  );
}
