import React, { createContext, useState, useMemo } from "react";
import Sidebar from "../ui/Sidebar";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { OrgSelector } from "../SuperAdmin/OrgSelector";
import CallNotificationManager from "../components/CallNotificationManager";

export const AppLayoutContext = createContext();

function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isUserInACall, setIsUserInACall] = useState(false);

  const isDevMode = localStorage.getItem("devmode") === "true";

  // Optimize context value with useMemo to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      sidebarOpen,
      setSidebarOpen,
      sidebarHovered,
      setSidebarHovered,
      isUserInACall,
      setIsUserInACall,
    }),
    [sidebarOpen, sidebarHovered, isUserInACall]
  );

  return (
    <AppLayoutContext.Provider value={contextValue}>
      <div className="flex h-dvh">
        <Sidebar />
        <div className="flex overflow-hidden relative flex-col flex-1">
          <CallNotificationManager
            isUserInACall={isUserInACall}
            setIsUserInACall={setIsUserInACall}
          />
          <main className="overflow-auto flex-1 bg-gray-100">
            <div className="p-6">
              {isDevMode && (
                <div className="py-4 mb-4 w-full border-b-2">
                  <OrgSelector />
                </div>
              )}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </AppLayoutContext.Provider>
  );
}

export default AppLayout;
