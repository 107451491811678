import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useF2FContext } from "../F2FContext";
import { useDaily } from "@daily-co/daily-react";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import BeforeF2F from "./BeforeF2F";
import VisitorsScrShare from "./TileComponents/VisitorsScrShare";
import DuringF2F from "./DuringF2F";
import { AppLayoutContext } from "../../layouts/AppLayout";
import Spinner from "../../ui/Spinner";
import CallRating from "./CallRating";

export default function CallingWindow() {
  const {
    visitorId,
    setRoomUrl,
    callAccepted,
    setCallAccepted,
    setSocket,
    screenShareOn,
    setIsScreenSharing,
    showCallUI,
    setshowCallUI,
    watchUrl,
    STATE_CREATING,
    STATE_IDLE,
    setOtherAgentTalkingToVisitor,
    isWatchUrlLoading,
  } = useF2FContext();

  const { isUserInACall, setIsUserInACall } = useContext(AppLayoutContext);
  const callObject = useDaily();
  const [appState, setAppState] = useState(STATE_IDLE);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const callRequest = queryParams.get("callRequest");
  const agentId = localStorage.getItem("userid");
  const stalkingActive = useRef(false); // Track if stalking was activated
  const [showRating, setShowRating] = useState(false);
  const callWasConnectedRef = useRef(false);

  const handleCallResponse = useCallback(
    debounce((data) => {
      if (!data.call_response) {
        if (callObject) {
          setRoomUrl(null);
        }

        console.log("handleCallResponse Triggered ,CallObject:", callObject);
        setAppState(STATE_IDLE);
        setshowCallUI(false);
        callWasConnectedRef.current = false; // Correct way to update ref
        toast.error("Call declined!");
      }
    }, 500),
    [callObject, setRoomUrl, setAppState, setshowCallUI]
  );

  const handleAnotherAgent = useCallback(
    debounce((data) => {
      if (data.agentId !== agentId) {
        if (callObject) {
          setRoomUrl(null);
        }
        console.log(data);
        setOtherAgentTalkingToVisitor(data.agentName);

        console.log("handleAnotherAgent Triggered ,CallObject:", callObject);
        setAppState(STATE_IDLE);
        setshowCallUI(() => false);
        callWasConnectedRef.current = false; // Correct way to update ref
        toast.error("Another agent in the call!");
      }
    }, 500),
    [
      callObject,
      setRoomUrl,
      setAppState,
      setshowCallUI,
      setOtherAgentTalkingToVisitor,
    ]
  );

  const handleCallEnded = useCallback(
    debounce(async (data) => {
      console.log("handleCallEnded Triggered", data, agentId);
      console.log(
        "Current callWasConnected state:",
        callWasConnectedRef.current
      );

      if (callObject) {
        try {
          await callObject.leave();
          console.log("Left the call successfully");
        } catch (error) {
          console.error("Error leaving the call:", error);
        }
        setRoomUrl(null);
      }

      console.log("handleCallEnded Triggered, CallObject:", callObject);
      setAppState(STATE_IDLE);
      setCallAccepted(false);
      setOtherAgentTalkingToVisitor(undefined);
      setshowCallUI(false);

      // Use the ref value which won't be affected by state batching or closures
      if (callWasConnectedRef.current) {
        console.log(
          "Setting showRating to true because call was connected (using ref)"
        );
        setShowRating(true);
      } else {
        console.log(
          "Not showing rating because call was not connected (using ref)"
        );
      }

      toast.success("Call ended!");
      setIsUserInACall(false);

      // Reset the connected state after deciding whether to show the rating
      callWasConnectedRef.current = false;

      queryParams.delete("callRequest");
      const newQueryString = queryParams.toString();
      const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

      window.history.replaceState(null, "", newUrl);
    }, 500),
    [
      callObject,
      setRoomUrl,
      setAppState,
      setCallAccepted,
      setshowCallUI,
      queryParams,
    ]
  );

  const handleVisitorNotConnected = useCallback(
    debounce(async (data) => {
      console.log("handleVisitorNotConnected Triggered");
      console.log(isUserInACall, data);
      if (data.agentId === agentId) {
        toast.error("Visitor didn't connect");
        console.log(
          "handleVisitorNotConnected Triggered  ,CallObject:",
          callObject
        );

        if (callObject) {
          try {
            await callObject.leave();
            console.log("Left the call successfully");
          } catch (error) {
            console.error("Error leaving the call:", error);
          }
          setRoomUrl(null);
        }

        setAppState(STATE_IDLE);
        setCallAccepted(false);
        setOtherAgentTalkingToVisitor(undefined);
        setshowCallUI(false);
        setIsUserInACall(false);
        callWasConnectedRef.current = false; // Correct way to update ref
        queryParams.delete("callRequest");
        const newQueryString = queryParams.toString();
        const newUrl = newQueryString
          ? `${window.location.pathname}?${newQueryString}`
          : window.location.pathname;

        window.history.replaceState(null, "", newUrl);
      }
    }, 500),
    [
      agentId,
      callObject,
      setRoomUrl,
      setAppState,
      setCallAccepted,
      setOtherAgentTalkingToVisitor,
      setshowCallUI,
      setIsUserInACall,
      queryParams,
    ]
  );

  const handleJoinCall = useCallback(
    debounce((data) => {
      setAppState(STATE_CREATING);
      console.log("handleJoinCall data:", data);

      try {
        //here we ask if the is room url and if the call request was meant for that agent
        if (data.room_url && data.agentId === agentId) {
          if (callObject) {
            void callObject.join({ url: data.room_url });
          }
          setRoomUrl(data.room_url);
          console.log(
            "handleJoinCall Triggered, CallObject:",
            callObject,
            "data room url:",
            data.room_url
          );
          setSocket((prevSocket) => {
            if (prevSocket) {
              prevSocket.emit("agent_connected", {
                agentName: localStorage.getItem("agentName"),
                f2fVisitorID: visitorId,
                agentId: localStorage.getItem("userid"),
              });
            }
            return prevSocket;
          });
          // apiSlackAgentAction("connected", visitorId);
          setCallAccepted(true);
          setshowCallUI(true);
          setIsUserInACall(true);

          // Use function form to ensure we're working with the latest state
          callWasConnectedRef.current = true;

          let currentUrl = window.location.href;

          // Create a new URL object
          let url = new URL(currentUrl);

          // Append or update the parameter
          url.searchParams.set("callRequest", "true");

          // Update the browser's address bar (optional, without reloading)
          window.history.replaceState(null, "", url.href);
        } else if (data.agentId !== agentId) {
          setOtherAgentTalkingToVisitor(data.agentName);
        } else {
          throw new Error("No room URL provided or not the same agent id");
        }
      } catch (error) {
        console.error("Error in handleJoinCall:", error);
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
          data: data,
          callObject: callObject,
          agentId: agentId,
        });
        setAppState(STATE_IDLE);
      }
    }, 500),
    [
      callObject,
      setAppState,
      setRoomUrl,
      setCallAccepted,
      agentId,
      setOtherAgentTalkingToVisitor,
      setshowCallUI,
      setIsUserInACall,
      visitorId,
    ]
  );

  useEffect(() => {
    const roomId = visitorId;
    const newSocket = io(process.env.REACT_APP_API_URL, {
      query: { target_id: roomId },
    });
    setSocket(newSocket);

    newSocket.on("f2f_call_response", handleCallResponse);
    newSocket.on("agent_in_call", handleAnotherAgent);
    newSocket.on("f2f_call_ended", handleCallEnded);
    newSocket.on("join_f2f_call", handleJoinCall);
    newSocket.on("visitor_not_connected", handleVisitorNotConnected);
    newSocket.on("screen_share_on", (data) => {
      if (data.f2fVisitorID === visitorId && data.userType === "agent") {
        setIsScreenSharing(true);
      }
    });
    newSocket.on("screen_share_off", (data) => {
      if (data.f2fVisitorID === visitorId && data.userType === "agent") {
        setIsScreenSharing(false);
      }
    });
    newSocket.on("error", (data) => {
      console.log(data.message);
    });

    if (callRequest) {
      newSocket.emit("start_f2f_call", {
        agentName: localStorage.getItem("agentName"),
        f2fVisitorID: visitorId,
        agentId: localStorage.getItem("userid"),
      });
      console.log("callObject=true start_f2f_call emitted");
    }

    return () => {
      // Emit agent_out_stalking only if stalking was previously active
      if (stalkingActive.current) {
        newSocket.emit("agent_out_stalking", {
          agentName: localStorage.getItem("agentName"),
          f2fVisitorID: visitorId,
          agentID: localStorage.getItem("userid"),
        });
        console.log("agent_out_stalking emitted");
      }
      // Cleanup socket listeners
      newSocket.off("f2f_call_response");
      newSocket.off("f2f_call_ended");
      newSocket.off("join_f2f_call");
      newSocket.off("error");
      newSocket.off("screen_share_on");
      newSocket.off("screen_share_off");
      newSocket.off("agent_in_call");

      newSocket.disconnect();
    };
  }, [location.search]);

  useEffect(() => {
    if (watchUrl) {
      console.log("watchUrl detected, emitting agent_in_stalking");

      setSocket((prevSocket) => {
        if (!prevSocket) return prevSocket;

        prevSocket.emit("agent_in_stalking", {
          agentName: localStorage.getItem("agentName"),
          f2fVisitorID: visitorId,
          agentID: localStorage.getItem("userid"),
        });
        stalkingActive.current = true;
        console.log("agent_in_stalking emitted");

        return prevSocket;
      });
    }
  }, [watchUrl]);

  useEffect(() => {
    if (callObject) {
      const handleParticipantJoined = (event) => {
        const participants = callObject.participants();
        console.log(
          "Participant joined, total participants:",
          Object.keys(participants).length
        );

        // If there are at least 2 participants (agent + visitor), mark the call as connected
        if (Object.keys(participants).length >= 2) {
          console.log(
            "Visitor connected to call - setting callWasConnectedRef to true"
          );
          callWasConnectedRef.current = true;
        }
      };

      callObject.on("participant-joined", handleParticipantJoined);

      return () => {
        callObject.off("participant-joined", handleParticipantJoined);
      };
    }
  }, [callObject]);

  return (
    <div className="relative rounded-3xl shadow-md">
      {isWatchUrlLoading && (
        <>
          <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
            <div
              className="flex absolute inset-0 justify-center items-center bg-center bg-cover"
              style={{
                backgroundImage: "url('/photos/bg-2.png')",
              }}
            >
              <Spinner />
            </div>
          </div>
        </>
      )}

      {watchUrl && !isWatchUrlLoading && (
        <>
          <div
            className={`${screenShareOn && "hidden"} relative w-full `}
            style={{ paddingTop: "56.25%" }}
          >
            <div
              className="absolute inset-0 bg-center bg-cover rounded-t-lg"
              style={{
                backgroundImage: "url('/photos/bg-2.png')",
              }}
            >
              <iframe
                src={watchUrl}
                title="mainScreen"
                className="absolute inset-0 w-full h-full"
                sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox allow-top-navigation"
              />

              {!screenShareOn && !callAccepted && (
                <div
                  className="absolute inset-0 bg-transparent"
                  style={{ zIndex: 10 }}
                />
              )}
            </div>
          </div>

          {screenShareOn && <VisitorsScrShare />}
        </>
      )}

      <div className="bg-white rounded-br-lg rounded-bl-lg">
        {showRating ? (
          <CallRating
            onComplete={() => setShowRating(false)}
            visitorId={visitorId}
          />
        ) : showCallUI ? (
          <DuringF2F setAppState={setAppState} />
        ) : (
          <BeforeF2F />
        )}
      </div>
    </div>
  );
}
