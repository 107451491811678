import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "../shadcn/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn/table";
import {
  ChevronLeft,
  ChevronRight,
  AlertCircle,
  ArrowDown,
} from "lucide-react";
import Spinner from "../ui/Spinner";
import apiCallHistory from "../modules/CallHistory/apiCallHistory";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/select";
import {
  BsTelephoneInboundFill,
  BsTelephoneOutboundFill,
} from "react-icons/bs";
import { HiXCircle } from "react-icons/hi";
import { FaCircleCheck } from "react-icons/fa6";
import { CiCircleMinus } from "react-icons/ci";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn/tooltip";
import { Separator } from "../shadcn/separator";
import SidebarOpenButton from "../ui/SidebarOpenButton";

const ITEMS_PER_PAGE = 50;

function flagemojiToPNG(countryCode) {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode?.toLowerCase()}.png`}
      alt="flag"
    />
  );
}

const getColumnsConfig = () => [
  { key: "type", label: "Type" },
  { key: "status", label: "Status/Duration" },
  { key: "visitor_id", label: "Visitor" },
  { key: "agent", label: "Agent" },
];

const calculateSessionDuration = (seconds) => {
  if (seconds < 60) {
    return `${seconds} s `;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} m `;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} h `;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} d `;
  }
};

const formatTimeAgo = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  return "Just now";
};

const getAgentName = (callData) => {
  // Outbound calls
  if (callData.type === "outbound") {
    return callData.user_name || "Unknown Agent";
  }

  // Inbound calls
  if (callData.type === "inbound") {
    switch (callData.status) {
      case "accepted":
        return (
          callData.accepted_by_name || callData.accepted_by || "Unknown Agent"
        );
      case "declined":
        // Handle declined_by_name as a list (e.g., take the first name)
        return callData.declined_by_name?.[0] || "Unknown Agent";
      case "missed":
        return "-"; // Missed calls have no agent name
      default:
        return "Unknown Agent";
    }
  }

  // Default fallback
  return "Unknown Agent";
};

export default function CallHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [response, setResponse] = useState("all");
  const [type, setType] = useState("all");
  const scrollRef = useRef(null);

  const { data, isLoading, error } = useQuery(
    ["history", currentPage, ITEMS_PER_PAGE, response, type],
    () =>
      apiCallHistory(
        currentPage,
        ITEMS_PER_PAGE,
        response === "all" ? "" : response,
        type === "all" ? "" : type
      ),
    {
      keepPreviousData: true,
    }
  );

  const totalPages = Math.ceil(data?.total / ITEMS_PER_PAGE);
  const columnsConfig = getColumnsConfig();

  useEffect(() => {
    setCurrentPage(1);
  }, [response, type]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= (totalPages || 1)) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  if (isLoading) return <Spinner />;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <div ref={scrollRef}></div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <div className="text-2xl font-bold pointer-events-none">
            Call History
          </div>
          {data?.total > 0 && <div> {data?.total} Calls</div>}
        </div>
        <SidebarOpenButton />
      </div>

      <div className="flex mb-4 space-x-4">
        <Select value={response} onValueChange={setResponse}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select response" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="accepted">Accepted</SelectItem>
            <SelectItem value="declined">Declined</SelectItem>
            <SelectItem value="missed">Missed</SelectItem>
          </SelectContent>
        </Select>

        <Select value={type} onValueChange={setType}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Types</SelectItem>
            <SelectItem value="inbound">Inbound</SelectItem>
            <SelectItem value="outbound">Outbound</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {data?.results.length > 0 ? (
        <div className="overflow-auto w-full">
          <Table className="bg-transparent sm:bg-white">
            <TableHeader className="hidden sm:table-header-group">
              <TableRow>
                {columnsConfig.map((column) => (
                  <TableHead key={column.key}>{column.label}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {data?.results.map((callData, index) => {
                const callDate = new Date(callData.created_at);
                const today = new Date();
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);

                const isToday =
                  callDate.toDateString() === today.toDateString();
                const isYesterday =
                  callDate.toDateString() === yesterday.toDateString();

                const showTodaySeparator = index === 0 && isToday;
                const showYesterdaySeparator =
                  (index === 0 && isYesterday) ||
                  (index > 0 &&
                    isYesterday &&
                    new Date(
                      data.results[index - 1].created_at
                    ).toDateString() === today.toDateString());

                return (
                  <React.Fragment key={callData._id}>
                    {showTodaySeparator && (
                      <TableRow>
                        <TableCell colSpan={4} className="py-2">
                          <div className="flex justify-between items-center">
                            <Separator className="w-1/3" />
                            <span className="px-2 text-sm font-medium cursor-default">
                              Today{" "}
                              <ArrowDown className="inline ml-1" size={16} />
                            </span>
                            <Separator className="w-1/3" />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    {showYesterdaySeparator && (
                      <TableRow>
                        <TableCell colSpan={4} className="py-2">
                          <div className="flex justify-between items-center">
                            <Separator className="w-1/3" />
                            <span className="px-2 text-sm font-medium">
                              Yesterday
                            </span>
                            <Separator className="w-1/3" />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow className="flex flex-col p-4 mb-4 bg-white rounded-lg sm:p-0 sm:table-row sm:bg-transparent sm:mb-0 hover:bg-white sm:hover:bg-gray-50">
                      {columnsConfig.map((column) => (
                        <TableCell
                          key={column.key}
                          className="flex flex-col px-2 py-4 sm:table-cell"
                        >
                          <div className="font-semibold sm:hidden">
                            {column.label} :
                          </div>

                          {column.key === "type" && (
                            <div className="flex gap-5 items-center">
                              {callData.type === "inbound" ? (
                                <BsTelephoneInboundFill />
                              ) : (
                                <BsTelephoneOutboundFill />
                              )}
                              <div className="flex flex-col gap-2">
                                <div> {`${callData.type}`}</div>
                                <div> {formatTimeAgo(callData.created_at)}</div>
                              </div>
                            </div>
                          )}

                          {column.key === "status" && (
                            <span>
                              {callData.status === "accepted" ? (
                                <div className="flex gap-2 items-center">
                                  <FaCircleCheck className="text-green-500" />{" "}
                                  {`connected • ${
                                    callData?.f2f_session
                                      ? calculateSessionDuration(
                                          callData.f2f_session.total_time_spent
                                        )
                                      : ""
                                  }`}
                                </div>
                              ) : callData.status === "missed" ? (
                                <div className="flex gap-2 items-center">
                                  <CiCircleMinus className="text-gray-500" />{" "}
                                  {callData.status}
                                </div>
                              ) : callData.status === "declined" ? (
                                <div className="flex gap-2 items-center">
                                  <HiXCircle className="text-red-500" />{" "}
                                  {callData.status}
                                </div>
                              ) : (
                                callData.status
                              )}
                            </span>
                          )}

                          {column.key === "visitor_id" && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      (window.location.href = `/visitors/${callData.visitor_id}`)
                                    }
                                  >
                                    {callData.visitor?.name ? (
                                      <div className="flex gap-2 items-center w-full">
                                        {flagemojiToPNG(
                                          callData.visitor.location.country
                                        )}
                                        <span className="overflow-hidden text-ellipsis whitespace-nowrap sm:w-[220px]">
                                          {callData.visitor.name}
                                        </span>
                                      </div>
                                    ) : (
                                      callData.visitor_id
                                    )}
                                  </span>
                                </TooltipTrigger>
                                {callData.visitor?.name && (
                                  <TooltipContent>
                                    <p>Click on the name for more info</p>
                                  </TooltipContent>
                                )}
                              </Tooltip>
                            </TooltipProvider>
                          )}

                          {column.key === "agent" && (
                            <span>{getAgentName(callData)}</span>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center p-8 bg-gray-100 rounded-lg">
          <AlertCircle className="mb-4 w-12 h-12 text-gray-400" />
          <p className="text-xl font-semibold text-gray-700">
            No data available
          </p>
        </div>
      )}

      <div className="flex justify-end items-center py-4 space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="w-4 h-4" />
        </Button>
        <div className="text-sm text-muted-foreground">
          Page {currentPage} of {totalPages}
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ChevronRight className="w-4 h-4" />
        </Button>
      </div>
    </>
  );
}
