import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../shadcn/tabs";
import { useNavigate, useParams } from "react-router-dom";
import {
  AccountTab,
  IntegrationsTab,
  OrganizationTab,
  PopupTab,
} from "../modules/Settings/tabs";

import SidebarOpenButton from "../ui/SidebarOpenButton";

export default function SettingsPage() {
  const [responsiveClass, setResponsiveClass] = useState("h-1300");
  const { tab } = useParams();
  const navigate = useNavigate();

  const availableTabs = ["account", "organization", "popup", "integrations"];
  const activeTab = tab && availableTabs.includes(tab) ? tab : "account";

  const handleTabChange = (newTab) => {
    if (newTab === "popup") {
      setResponsiveClass("h-1300");
    } else {
      setResponsiveClass("");
    }

    navigate(`/settings/${newTab}`); // Update the URL when the tab changes
  };

  return (
    <div className="h-full">
      <div className="flex justify-end pb-2">
        <SidebarOpenButton />
      </div>

      <Tabs
        defaultValue={activeTab}
        value={activeTab}
        className={"w-full " + responsiveClass}
        onValueChange={handleTabChange}
      >
        <TabsList className="grid grid-cols-2 w-full h-auto bg-gray-50 min-[420px]:grid-cols-4">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="organization">Organization</TabsTrigger>
          <TabsTrigger value="popup">Popup</TabsTrigger>
          <TabsTrigger value="integrations">Integrations</TabsTrigger>
        </TabsList>

        <TabsContent value="account">
          <AccountTab />
        </TabsContent>

        <TabsContent value="organization">
          <OrganizationTab />
        </TabsContent>

        <TabsContent value="popup" className="p-0 h-full bg-white border-none">
          <PopupTab />
        </TabsContent>

        <TabsContent value="integrations">
          <IntegrationsTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
