import React, { useEffect, useState } from "react";
import { Card, CardContent } from "../../../shadcn/card";
import { Button } from "../../../shadcn/button";
import apiPopups from "../WorkflowApis/apiPopups";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../ui/Spinner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shadcn/dropdown-menu";
import { FiEdit, FiMoreVertical } from "react-icons/fi";
import { Switch } from "../../../shadcn/switch";
import { Alert, AlertDescription, AlertTitle } from "../../../shadcn/alert";
import { AlertCircle } from "lucide-react";

export default function Step2({ workflowData, setWorkflowData, isEditMode }) {
  const [popups, setPopups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPopup, setSelectedPopup] = useState();
  const [noPopupsAvailable, setNoPopupsAvailable] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPopups = async () => {
      try {
        const data = await apiPopups();
        const filteredData = data.filter(
          (popup) => popup.type === "floating_popup"
        );

        // Check if there are no floating popups available
        if (filteredData.length === 0) {
          setNoPopupsAvailable(true);
          setIsLoading(false);
          return;
        }

        // Find default popup (without a name) and ensure it's set to workflow
        const defaultPopup = filteredData.find((popup) => !popup.name);

        // Check if we need to select a popup
        const selectAppropriatePopup = () => {
          // Case 1: If in edit mode and the selected popup exists in our filtered list
          if (
            isEditMode &&
            workflowData.action &&
            workflowData.action.popup_id &&
            filteredData.some(
              (popup) => popup._id === workflowData.action.popup_id
            )
          ) {
            setSelectedPopup(workflowData.action.popup_id);
            return true;
          }

          // Case 2: If we have a default popup, select it
          if (defaultPopup) {
            setWorkflowData((prevData) => ({
              ...prevData,
              action: { ...prevData.action, popup_id: defaultPopup._id },
            }));
            setSelectedPopup(defaultPopup._id);
            return true;
          }

          // Case 3: If no default popup and no valid selected popup, select the first one
          if (filteredData.length > 0) {
            setWorkflowData((prevData) => ({
              ...prevData,
              action: { ...prevData.action, popup_id: filteredData[0]._id },
            }));
            setSelectedPopup(filteredData[0]._id);
            return true;
          }

          return false;
        };

        selectAppropriatePopup();

        // Sort the popups array with the default popup at the start
        const sortedPopups = [
          ...(defaultPopup ? [defaultPopup] : []),
          ...filteredData.filter((popup) => popup !== defaultPopup),
        ];

        setPopups(sortedPopups);
      } catch (error) {
        console.error("Error fetching popup ids:", error);
        setNoPopupsAvailable(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPopups();
  }, []);

  const handleEdit = (id) => {
    navigate(`/settings/popup?editpopup=${id}`);
  };

  const handleCardClick = (popupId) => {
    setSelectedPopup(popupId);
    setWorkflowData((prevData) => ({
      ...prevData,
      action: { ...prevData.action, popup_id: popupId },
    }));
  };

  const handleToggle = (popupId) => {
    setWorkflowData((prevData) => {
      const newAction = { ...prevData.action };
      if (newAction.hide_toggle === undefined) {
        newAction.hide_toggle = true;
      } else {
        newAction.hide_toggle = !newAction.hide_toggle;
      }
      return { ...prevData, action: newAction };
    });
  };

  return (
    <div className="space-y-6 w-full max-w-3xl">
      <div className="space-y-2">
        <h3 className="text-muted-foreground">
          Select which floating popup you&apos;d like to use
        </h3>
      </div>

      <div className="space-y-4">
        {isLoading ? (
          <Spinner />
        ) : noPopupsAvailable ? (
          <div className="space-y-4">
            <Alert variant="destructive">
              <AlertCircle className="w-4 h-4" />
              <AlertTitle>No Popups Available</AlertTitle>
              <AlertDescription>
                There are no floating popups available to select. You need to
                create a popup first to continue with this workflow.
              </AlertDescription>
            </Alert>
          </div>
        ) : (
          popups.map((popup) => (
            <Card
              key={popup._id}
              className={`shadow-none transition-colors cursor-pointer hover:bg-accent/50 ${
                selectedPopup === popup._id
                  ? "bg-accent border-2 border-primary"
                  : ""
              }`}
              onClick={() => handleCardClick(popup._id)}
            >
              <CardContent className="flex justify-between items-center p-4">
                <div>
                  <p className="text-[13px] text-muted-foreground">
                    {popup.name ? "Custom Popup" : "Default Popup"}
                  </p>
                  <p className="font-semibold text-md">
                    {popup.name || "Default Popup"}
                  </p>
                </div>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="p-0 w-8 h-8">
                      <FiMoreVertical className="w-4 h-4" />
                      <span className="sr-only">Open menu</span>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={(e) => handleEdit(popup._id, e)}>
                      <FiEdit className="mr-4 ml-4 w-4 h-4" />
                      <span>Edit</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <div className="flex gap-1 justify-between items-center w-full">
                        <Switch
                          id={`hide-toggle-${popup._id}`}
                          checked={workflowData.action?.hide_toggle ?? false}
                          onCheckedChange={() => handleToggle(popup._id)}
                        />
                        <span>Hide Toggle</span>
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </CardContent>
            </Card>
          ))
        )}
      </div>
    </div>
  );
}
