export default async function apiCallRating(visitorId, rating, feedback) {
  const orgid = localStorage.getItem("orgid");
  const userid = localStorage.getItem("userid");
  const apikey = localStorage.getItem("apikey");
  const agentName = localStorage.getItem("agentName");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/front-actions/call_rating`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apikey,
          "X-Org-Id": orgid,
          "X-User-Id": userid,
        },
        body: JSON.stringify({
          agent_id: userid,
          agent_name: agentName,
          comment: feedback || "",
          f2f_visitor_id: visitorId,
          rating: Number(rating),
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Call rating failed with status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting call rating", error);
    throw error;
  }
}
