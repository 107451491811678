import { useNavigate } from "react-router-dom";
import Container from "../ui/Container";
import { IoArrowBack } from "react-icons/io5";
import SidebarOpenButton from "../ui/SidebarOpenButton";

function Live() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-center">
        <button
          className="min-[300px]:h-10 h-8 gap-2 min-[300px]:gap-4  rounded-3xl text-black bg-white border border-transparent hover:border-black active:border-black  flex items-center justify-center  p-2 min-[300px]:p-3.5 "
          onClick={() => navigate("/visitors")}
        >
          <IoArrowBack /> <span>Back</span>
        </button>

        <SidebarOpenButton />
      </div>
      <Container />
    </>
  );
}

export default Live;
