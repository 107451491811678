import { useContext, useMemo } from "react";
import { BsStars } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { GoGear } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import { LuHistory } from "react-icons/lu";
import { AppLayoutContext } from "../layouts/AppLayout";
import { PiFileVideo } from "react-icons/pi";

// NavItem component to reduce re-renders of individual items
const NavItem = ({ item, expanded }) => {
  const { setSidebarOpen } = useContext(AppLayoutContext);

  return (
    <li className="flex justify-center items-center h-14 text-black bg-white rounded-lg hover:bg-black hover:text-white">
      <NavLink
        to={item.to}
        className={`flex items-center w-full h-full ${
          expanded ? "gap-3 justify-start px-5" : "justify-center"
        } transition-all duration-300`}
        onClick={() => setSidebarOpen(false)}
      >
        <span className="flex flex-shrink-0 justify-center items-center w-5 h-5">
          <item.icon className="w-5 h-5" />
        </span>
        {expanded && <span className="text-lg">{item.label}</span>}
      </NavLink>
    </li>
  );
};

function MainNav({ expanded }) {
  // Memoize nav items to prevent recreation on each render
  const navItems = useMemo(() => {
    const baseNavItems = [
      { icon: RxDashboard, label: "Dashboard", to: "/dashboard" },
      { icon: FiUsers, label: "Visitors", to: "/visitors" },
      { icon: LuHistory, label: "History", to: "/history" },
      { icon: BsStars, label: "Workflows", to: "/workflows" },
    ];

    const settingsItem = { icon: GoGear, label: "Settings", to: "/settings" };

    return localStorage.getItem("adminkey")
      ? [
          ...baseNavItems,
          { icon: PiFileVideo, label: "Recordings", to: "/recordings" },
          settingsItem,
        ]
      : [...baseNavItems, settingsItem];
  }, []);

  return (
    <nav>
      <ul className="flex flex-col gap-4 pl-0 w-full text-xl list-none">
        {navItems.map((item) => (
          <NavItem key={item.to} item={item} expanded={expanded} />
        ))}
      </ul>
    </nav>
  );
}

export default MainNav;
