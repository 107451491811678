export default async function startStalkingSession(visitorUpscopeShortId) {
  const orgid = localStorage.getItem("orgid");
  const userid = localStorage.getItem("userid");
  const apikey = localStorage.getItem("apikey");
  const agentName = localStorage.getItem("agentName");

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/upscope/start-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": apikey,
          "X-Org-Id": orgid,
          "X-User-Id": userid,
        },
        body: JSON.stringify({
          visitorId: visitorUpscopeShortId,
          agentName: agentName,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result; // Return the result
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error so it can be caught in the useEffect
  }
}
