import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppLayout from "./layouts/AppLayout";
import Visitors from "./pages/Visitors";
import Live from "./pages/Live";
import LandingPage from "./pages/LandingPage";
import SignUpForm from "./ui/SignUpForm";
import Workflows from "./pages/Workflows";
import DashboardNew from "./pages/DashboardNew";
import SettingsPage from "./pages/SettingsPage";
import ProtectedRoute from "./ui/ProtectedRoute";
import { Toaster } from "react-hot-toast";
import { DailyProvider } from "@daily-co/daily-react";
import Daily from "@daily-co/daily-js";
import { useState, useEffect, useRef } from "react";
import Invitation from "./pages/Invitation";
import { WorkflowProvider } from "./modules/Workflows/WorkflowContextProvider";
import WaitListPage from "./pages/WaitListPage";
import CallHistory from "./pages/CallHistory";
import RecordingsRRWPage from "./pages/RecordingsRRWPage";
import SingleRecording from "./modules/RecordingPage/SingleRecording";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  const [callObject, setCallObject] = useState(null);
  const hasInitializedCallObject = useRef(false);

  useEffect(() => {
    if (!callObject && !hasInitializedCallObject.current) {
      const newCallObject = Daily.createCallObject();
      hasInitializedCallObject.current = true;
      setCallObject(newCallObject);
    }
  }, [callObject]);

  return (
    <QueryClientProvider client={queryClient}>
      <DailyProvider callObject={callObject}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LandingPage />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AppLayout />
                </ProtectedRoute>
              }
            >
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<DashboardNew />} />
              <Route path="/visitors" element={<Visitors />} />
              <Route path="/visitors/:id" element={<Live />} />
              <Route path="/recordings" element={<RecordingsRRWPage />} />
              <Route path="/recordings/:id" element={<SingleRecording />} />
              <Route path="/history" element={<CallHistory />} />
              <Route
                path="/workflows"
                element={
                  <WorkflowProvider>
                    <Workflows />
                  </WorkflowProvider>
                }
              />
              <Route path="/settings/:tab?" element={<SettingsPage />} />
            </Route>
            <Route path="test" element={<SignUpForm />} />
            <Route path="/invitation/:inviteid" element={<Invitation />} />
            <Route path="/waitlist" element={<WaitListPage />} />
          </Routes>
        </BrowserRouter>
        <Toaster
          position="top-center"
          gutter={12}
          containerStyle={{ margin: "8px" }}
          toastOptions={{
            success: { duration: 2000 },
            error: { duration: 4000 },
            style: {
              fontSize: "16px",
              maxWidth: "500px",
              padding: "16px 24px",
              backgroundColor: "white",
              color: "black",
            },
          }}
        />
      </DailyProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
