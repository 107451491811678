import { useState } from "react";
import { Button } from "../../../shadcn/button";
import { Label } from "../../../shadcn/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../shadcn/card";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import ChannelsDropdown from "../ChannelsDropdown";
import ApiRevealButton from "../ApiRevealButton";
import { WebhookIntegration } from "../WebhookIntegrations/WebhookIntegration";

const IntegrationsTab = () => {
  const isIntegrated = localStorage.getItem("slackIntegrated") === "true";
  const discordIntegrated =
    localStorage.getItem("discordIntegrated") === "true";

  const handleSlack = () => {
    const org_id = localStorage.getItem("orgid");
    window.location.href = `${process.env.REACT_APP_API_URL}/integrations/slack/integrate?org_id=${org_id}`;
  };

  const handleDiscord = () => {
    const org_id = localStorage.getItem("orgid");
    window.location.href = `${process.env.REACT_APP_API_URL}/integrations/discord/integrate?org_id=${org_id}`;
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Slack Integration</CardTitle>
          <CardDescription>Manage your Slack integration here.</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {isIntegrated ? (
            <div className="flex flex-col gap-4 items-start space-x-2 text-green-600">
              <div className="flex items-center space-x-2">
                <FaCheckCircle className="w-5 h-5" />
                <Label>Your organization is connected to Slack.</Label>
              </div>

              <div className="flex justify-between items-center w-full max-[610px]:flex-col max-[610px]:gap-4 max-[610px]:items-start">
                <Button className="text-wrap" onClick={handleSlack}>
                  Click here to integrate slack on other workspace
                </Button>
                <ChannelsDropdown listFor={"Slack"} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex items-center space-x-2 text-red-500">
                <FaTimesCircle className="w-5 h-5" />
                <Label>Your organization is not connected to Slack.</Label>
              </div>

              <Button className="w-64 text-wrap" onClick={handleSlack}>
                Click here to integrate Slack
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <Card className="mt-4">
        <CardHeader>
          <CardTitle>Discord Integration</CardTitle>
          <CardDescription>
            Manage your Discord integration here.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {discordIntegrated ? (
            <div className="flex flex-col gap-4 items-start space-x-2 text-green-600">
              <div className="flex items-center space-x-2">
                <FaCheckCircle className="w-5 h-5" />
                <Label>Your organization is connected to Discord.</Label>
              </div>

              <div className="flex justify-between items-center w-full max-[610px]:flex-col max-[610px]:gap-4 max-[610px]:items-start">
                <Button className="text-wrap" onClick={handleDiscord}>
                  Click here to integrate discord on other server
                </Button>
                <ChannelsDropdown listFor={"Discord"} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-4">
              <div className="flex items-center space-x-2 text-red-500">
                <FaTimesCircle className="w-5 h-5" />
                <Label>Your organization is not connected to Discord.</Label>
              </div>

              <Button className="w-64 text-wrap" onClick={handleDiscord}>
                Click here to integrate Discord
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <WebhookIntegration />

      <Card className="mt-4">
        <CardHeader>
          <CardTitle>Face2Face API</CardTitle>
          <CardDescription>
            Use our API to integrate Face2Face with your workflows.&nbsp;
            <a
              href="https://docs.face2face.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400"
            >
              Click here for the developer documentation.
            </a>
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex flex-col gap-4">
            <ApiRevealButton />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default IntegrationsTab;
