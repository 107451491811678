import React, { createContext, useState, useContext, useEffect } from "react";
import startStalkingSession from "./F2FApis/startStalkingSession";

const F2FContext = createContext();

export const useF2FContext = () => {
  const context = useContext(F2FContext);
  if (!context) {
    throw new Error("useF2FContext must be used within an F2FProvider");
  }
  return context;
};

export const F2FProvider = ({ children, visitorData }) => {
  const STATE_IDLE = "STATE_IDLE";
  const STATE_CREATING = "STATE_CREATING";
  const STATE_LEAVING = "STATE_LEAVING";

  const { agents_in_f2f_session = false, in_f2f_session = false } =
    visitorData.visitor.statuses || {};

  const {
    _id: visitorId,
    upscope_short_id: visitorUpscopeShortId,
    online,
  } = visitorData.visitor;

  console.log("agents_in_f2f_session", agents_in_f2f_session);
  console.log("in_f2f_session", in_f2f_session);

  const [stalking, setStalking] = useState(true);
  const [watchUrl, setWatchUrl] = useState();
  const [roomUrl, setRoomUrl] = useState("");
  const [showCallUI, setshowCallUI] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [socket, setSocket] = useState(null);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [otherAgentTalkingToVisitor, setOtherAgentTalkingToVisitor] =
    useState();
  const [isWatchUrlLoading, setIsWatchUrlLoading] = useState(false);

  useEffect(() => {
    console.log("iz konteksta agent name", otherAgentTalkingToVisitor);
  }, [otherAgentTalkingToVisitor]);

  useEffect(() => {
    async function startStalking() {
      try {
        if (online) {
          setIsWatchUrlLoading(true);
          const data = await startStalkingSession(visitorUpscopeShortId);
          setWatchUrl(data.watch_url);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsWatchUrlLoading(false);
      }
    }

    startStalking();
  }, [online, visitorUpscopeShortId]);

  const value = {
    agents_in_f2f_session,
    in_f2f_session,
    visitorId,
    visitorUpscopeShortId,
    online,
    stalking,
    setStalking,
    watchUrl,
    roomUrl,
    setRoomUrl,
    isScreenSharing,
    setIsScreenSharing,
    screenShareOn,
    setScreenShareOn,
    socket,
    setSocket,
    callAccepted,
    setCallAccepted,
    showCallUI,
    setshowCallUI,
    STATE_IDLE,
    STATE_CREATING,
    STATE_LEAVING,
    otherAgentTalkingToVisitor,
    setOtherAgentTalkingToVisitor,
    isWatchUrlLoading,
  };

  return <F2FContext.Provider value={value}>{children}</F2FContext.Provider>;
};
