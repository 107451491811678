import React, { useState, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiCheckForCalling from "../Signup/apiCheckForCalling";
import apiDeclineCall from "../Signup/apiDeclineCall";
import apiSlackAgentAction from "../Utils/apiSlackAgentAction";
import { IncomingCallNotification } from "../ui/IncomingCallNotification";

const CallNotificationManager = ({ isUserInACall, setIsUserInACall }) => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [debounce, setDebounce] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const [acceptedRequests, setAcceptedRequests] = useState(new Set());
  const [declinedRequests, setDeclinedRequests] = useState(new Set());

  const playNotificationSound = useCallback(async () => {
    if (audioRef.current && !isPlaying) {
      try {
        audioRef.current.loop = true;
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
  }, [isPlaying]);

  const stopNotificationSound = useCallback(() => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  }, [isPlaying]);

  const fetchCallingStatus = useCallback(async () => {
    try {
      const data = await apiCheckForCalling();

      // Filter out both declined and accepted requests
      const filteredRequests = data.call_requests.filter(
        (request) =>
          !declinedRequests.has(request.visitor_id) &&
          !acceptedRequests.has(request.visitor_id)
      );

      setPendingRequests(filteredRequests);

      if (filteredRequests.length > 0 && !isPlaying && !debounce) {
        await playNotificationSound();
      } else if (filteredRequests.length === 0 && isPlaying) {
        stopNotificationSound();
      }
    } catch (error) {
      console.error("Error fetching calling status:", error);
      if (isPlaying) {
        stopNotificationSound();
      }
    }
  }, [
    isPlaying,
    debounce,
    declinedRequests,
    acceptedRequests,
    playNotificationSound,
    stopNotificationSound,
  ]);

  useEffect(() => {
    if (!isUserInACall) {
      fetchCallingStatus();
    }

    const intervalId = setInterval(() => {
      if (!isUserInACall) {
        fetchCallingStatus();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [isUserInACall, fetchCallingStatus]);

  const acceptCall = useCallback(async () => {
    if (pendingRequests.length > 0) {
      const acceptedRequest = pendingRequests[0];

      // apiSlackAgentAction("agent_accepted", acceptedRequest.visitor_id);

      const currentPath = window.location.pathname;
      setPendingRequests((prevRequests) =>
        prevRequests.filter(
          (request) => request.visitor_id !== acceptedRequest.visitor_id
        )
      );

      setAcceptedRequests((prev) =>
        new Set(prev).add(acceptedRequest.visitor_id)
      );

      stopNotificationSound();

      // Update the parent component's state
      setIsUserInACall(true);

      if (currentPath.includes(`/visitors/${acceptedRequest.visitor_id}`)) {
        const timestamp = new Date().getTime();
        navigate(
          `/visitors/${acceptedRequest.visitor_id}?callRequest=true&refresh=${timestamp}`
        );
      } else {
        navigate(`/visitors/${acceptedRequest.visitor_id}?callRequest=true`);
      }

      setDebounce(true);
      setTimeout(() => setDebounce(false), 4000);

      // Remove from acceptedRequests after 10 seconds
      setTimeout(() => {
        setAcceptedRequests((prev) => {
          const updated = new Set(prev);
          updated.delete(acceptedRequest.visitor_id);
          return updated;
        });
      }, 10000);
    }
  }, [pendingRequests, navigate, stopNotificationSound, setIsUserInACall]);

  const declineCall = useCallback(async () => {
    if (pendingRequests.length > 0) {
      try {
        const declinedRequest = pendingRequests[0];

        apiSlackAgentAction("agent_declined", declinedRequest.visitor_id);

        setDeclinedRequests((prev) =>
          new Set(prev).add(declinedRequest.visitor_id)
        );
        setPendingRequests((prevRequests) =>
          prevRequests.filter(
            (request) => request.visitor_id !== declinedRequest.visitor_id
          )
        );

        stopNotificationSound();
        setDebounce(true);

        await apiDeclineCall(declinedRequest.visitor_id);

        setTimeout(() => setDebounce(false), 3000);

        // Remove from declinedRequests after 10 seconds
        setTimeout(() => {
          setDeclinedRequests((prev) => {
            const updated = new Set(prev);
            updated.delete(declinedRequest.visitor_id);
            return updated;
          });
        }, 10000);
      } catch (error) {
        console.error("Error declining call:", error);
        setDebounce(false);
      }
    }
  }, [pendingRequests, stopNotificationSound]);

  return (
    <>
      {!debounce && pendingRequests.length > 0 && (
        <div className="absolute top-4 right-4 z-50">
          <IncomingCallNotification
            visitor={pendingRequests[0].visitor}
            acceptCall={acceptCall}
            declineCall={declineCall}
          />
        </div>
      )}
      <audio ref={audioRef} src="/sounds/F2F-RINGTONE.MP3" />
    </>
  );
};

export default CallNotificationManager;
