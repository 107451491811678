import React, { useCallback, useEffect } from "react";
import {
  useAudioTrack,
  useDaily,
  useLocalSessionId,
  useVideoTrack,
  useScreenShare,
} from "@daily-co/daily-react";
import { Screenshare } from "./Icons";

import { VideoControlButton } from "./VideoControlButton";
import {
  PiMicrophoneSlashFill,
  PiMicrophoneFill,
  PiVideoCameraSlashFill,
  PiVideoCameraFill,
} from "react-icons/pi";

import { isMobile } from "react-device-detect";
import { useF2FContext } from "../F2FContext";

export default function Tray({ startLeavingCall }) {
  const {
    callAccepted,
    isScreenSharing,
    setIsScreenSharing,
    socket,
    visitorId,
  } = useF2FContext();

  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const localAudio = useAudioTrack(localSessionId);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const callObject = useDaily();
  const { isSharingScreen, startScreenShare, stopScreenShare } =
    useScreenShare();

  const toggleVideo = useCallback(() => {
    callObject.setLocalVideo(mutedVideo);
  }, [mutedVideo, callObject]);

  const toggleAudio = useCallback(() => {
    callObject.setLocalAudio(mutedAudio);
  }, [mutedAudio, callObject]);

  const toggleScreenShare = async () => {
    if (isSharingScreen) {
      stopScreenShare();
      setIsScreenSharing(false);
      socket.emit("screen_share_off", {
        f2fVisitorID: visitorId,
        userType: "agent",
      });
    } else {
      try {
        startScreenShare();

        setIsScreenSharing(true);
      } catch (error) {
        console.error("Error starting screen share:", error);
      }
    }
  };

  useEffect(() => {
    const handleTrackStarted = (event) => {
      if (event.type === "screenVideo" && event.participant.local) {
        setIsScreenSharing(true);
        socket.emit("screen_share_on", {
          f2fVisitorID: visitorId,
          userType: "agent",
        });
      }
    };

    if (callObject) {
      callObject.on("track-started", handleTrackStarted);
    }

    return () => {
      if (callObject) {
        callObject.off("track-started", handleTrackStarted);
      }

      if (isScreenSharing) {
        socket.emit("screen_share_off", {
          f2fVisitorID: visitorId,
          userType: "agent",
        });
      }
    };
  }, [callObject, setIsScreenSharing, socket, visitorId, isScreenSharing]);

  return (
    <div className="w-full">
      <div className="flex flex-row gap-3 w-full">
        {callAccepted && !isMobile && (
          <VideoControlButton
            onClick={toggleScreenShare}
            tooltipText={
              isSharingScreen ? "Stop sharing screen" : "Share your screen"
            }
            active={isSharingScreen}
            notEndCall={true}
          >
            <Screenshare className="w-5 h-5" />
          </VideoControlButton>
        )}
        {callAccepted && (
          <VideoControlButton
            onClick={toggleVideo}
            tooltipText={mutedVideo ? "Turn on camera" : "Turn off camera"}
            active={!mutedVideo}
            notEndCall={true}
          >
            {mutedVideo ? (
              <PiVideoCameraSlashFill className="w-5 h-5" />
            ) : (
              <PiVideoCameraFill className="w-5 h-5" />
            )}
          </VideoControlButton>
        )}
        {callAccepted && (
          <VideoControlButton
            onClick={toggleAudio}
            tooltipText={mutedAudio ? "Unmute microphone" : "Mute microphone"}
            active={!mutedAudio}
            notEndCall={true}
          >
            {mutedAudio ? (
              <PiMicrophoneSlashFill className="w-5 h-5" />
            ) : (
              <PiMicrophoneFill className="w-5 h-5" />
            )}
          </VideoControlButton>
        )}
        <VideoControlButton
          notEndCall={false}
          onClick={startLeavingCall}
          tooltipText="End Call"
        >
          <div className="flex justify-center items-center p-2 h-6 font-semibold text-white bg-red-500 rounded-lg sm:p-4">
            <span className="text-xs whitespace-nowrap sm:text-sm md:text-base">
              End Call
            </span>
          </div>
        </VideoControlButton>
      </div>
    </div>
  );
}
