import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "../../lib/utils";
import { Button } from "../../shadcn/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../shadcn/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../shadcn/popover";

export function DropDownWithSearch({ suggestions = [], onSelect, className }) {
  const [value, setValue] = React.useState("");
  const [activeFilter, setActiveFilter] = React.useState("all");
  // Add ref for the command group
  const commandGroupRef = React.useRef(null);

  // Function to reset scroll position
  const resetScroll = () => {
    if (commandGroupRef.current) {
      commandGroupRef.current.scrollTop = 0;
    }
  };

  // Format display text while keeping original value
  const formatDisplayText = (text) => {
    return text
      .replace("visitor.", "")
      .split(".")
      .map((part) =>
        part
          .replace(/_/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      )
      .join(" › ");
  };

  // Filter categories
  const filterCategories = {
    all: (item) => true,
    basic: (item) => {
      const basicFields = [
        "_id",
        "name",
        "browser",
        "device",
        "ip",
        "location",
        "timezone",
        "current_page",
      ];
      return basicFields.some((field) => item.includes(field));
    },
    sessions: (item) => {
      return (
        item.includes("total_") ||
        item.includes("sessions") ||
        item.includes("f2f") ||
        item.includes("screenshare")
      );
    },
    data: (item) => {
      return item.includes("data.") || item.includes("workflows_triggers");
    },
  };

  const filteredSuggestions = suggestions.filter(
    filterCategories[activeFilter]
  );

  return (
    <div className={cn("rounded-lg border shadow-md", className)}>
      <div className="flex flex-wrap gap-1 p-2 bg-white border-b">
        <Button
          variant={activeFilter === "all" ? "default" : "outline"}
          size="sm"
          className="flex-1 min-w-[80px] text-xs sm:text-sm"
          onClick={() => {
            setActiveFilter("all");
            resetScroll();
          }}
        >
          All Fields
        </Button>
        <Button
          variant={activeFilter === "basic" ? "default" : "outline"}
          size="sm"
          className="flex-1 min-w-[80px] text-xs sm:text-sm"
          onClick={() => {
            setActiveFilter("basic");
            resetScroll();
          }}
        >
          Basic Info
        </Button>
        <Button
          variant={activeFilter === "sessions" ? "default" : "outline"}
          size="sm"
          className="flex-1 min-w-[80px] text-xs sm:text-sm"
          onClick={() => {
            setActiveFilter("sessions");
            resetScroll();
          }}
        >
          Session Stats
        </Button>
        <Button
          variant={activeFilter === "data" ? "default" : "outline"}
          size="sm"
          className="flex-1 min-w-[80px] text-xs sm:text-sm"
          onClick={() => {
            setActiveFilter("data");
            resetScroll();
          }}
        >
          Custom Data
        </Button>
      </div>
      <Command className="w-full">
        <CommandInput
          placeholder="Search fields..."
          className="border-none focus:ring-0"
          onValueChange={() => resetScroll()}
        />
        <CommandList>
          <CommandEmpty>No fields found.</CommandEmpty>
          <CommandGroup
            ref={commandGroupRef}
            className="max-h-[150px] overflow-auto p-1"
          >
            {filteredSuggestions.map((suggestion) => (
              <CommandItem
                key={suggestion}
                value={suggestion}
                onSelect={(currentValue) => {
                  setValue(currentValue);
                  if (onSelect) {
                    onSelect(suggestion);
                  }
                }}
                className="flex items-center"
              >
                <div className="flex flex-col w-full truncate">
                  <span className="font-medium">
                    {formatDisplayText(suggestion)}
                  </span>
                  <span className="text-xs text-muted-foreground">
                    {suggestion}
                  </span>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </div>
  );
}
