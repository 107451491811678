import { useState } from "react";
import { Button } from "../../../shadcn/button";
import { Input } from "../../../shadcn/input";
import { Label } from "../../../shadcn/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../shadcn/card";
import apiUpdateOrgName from "../../../Signup/apiUpdateOrgName";
import toast from "react-hot-toast";
import OrgReveal from "../OrgReveal";
import TeamPanel from "../../../pages/TeamPanel";

const OrganizationTab = () => {
  const [orgName, setOrgName] = useState(localStorage.getItem("orgName") || "");

  const handleUpdateOrgName = async () => {
    try {
      await apiUpdateOrgName(orgName);
      toast.success("Organization name updated successfully!");
    } catch (error) {
      console.error("Error updating organization name:", error);
      toast.error("Failed to update organization name. Please try again.");
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Organization Settings</CardTitle>
          <CardDescription>Manage your organization and team.</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="companyName">Company Name</Label>
            <Input
              id="companyName"
              placeholder={orgName}
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter className="flex-col min-[500px]:flex-row gap-2 justify-between items-start min-[500px]:items-center">
          <Button onClick={handleUpdateOrgName}>Save Changes</Button>
          <OrgReveal />
        </CardFooter>
      </Card>

      <Card className="mt-4">
        <CardHeader>
          <CardTitle>Members</CardTitle>
        </CardHeader>
        <CardContent className="space-y-8">
          <TeamPanel />
        </CardContent>
      </Card>
    </>
  );
};

export default OrganizationTab;
