import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn/table";
import { Button } from "../../shadcn/button";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import apiAllRecordings from "./RecordingApis/apiAllRecordings";
import Spinner from "../../ui/Spinner";
import { Link } from "react-router-dom";
import { RecordingsFilter } from "./RecordingFilter";

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function RecordingsTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(
    Number.parseInt(searchParams.get("page") || "1", 10)
  );

  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecordings, setTotalRecordings] = useState(0);

  const BATCH_SIZE = 10;

  useEffect(() => {
    async function fetchRecordings() {
      setLoading(true);
      try {
        const org_id = searchParams.get("org_id");
        const visitor_id = searchParams.get("visitor_id");

        const data = await apiAllRecordings({
          page: currentPage,
          batch_size: BATCH_SIZE,
          org_id,
          visitor_id,
        });
        setRecordings(data.recordings);
        setTotalRecordings(data.total_recordings);
      } catch (error) {
        console.error("Error fetching recordings:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchRecordings();
  }, [currentPage, BATCH_SIZE, searchParams]);

  useEffect(() => {
    const page = Number.parseInt(searchParams.get("page") || "1", 10);
    setCurrentPage(page);
  }, [searchParams]);

  const totalPages = Math.ceil(totalRecordings / BATCH_SIZE);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set("page", newPage.toString());
        return params;
      });
      setCurrentPage(newPage);
    }
  };

  const handleApplyFilter = (orgId, visitorId) => {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);

      if (orgId) {
        params.set("org_id", orgId);
      } else {
        params.delete("org_id");
      }

      if (visitorId) {
        params.set("visitor_id", visitorId);
      } else {
        params.delete("visitor_id");
      }

      // Reset to first page when applying filters
      params.set("page", "1");
      return params;
    });
  };

  if (loading) {
    return <Spinner />;
  }

  const org_id = searchParams.get("org_id");
  const visitor_id = searchParams.get("visitor_id");

  if (recordings.length === 0) {
    let message = "No recordings found";
    if (org_id && visitor_id) {
      message =
        "No recordings found for this visitor in the selected organization";
    } else if (visitor_id) {
      message = "No recordings found for this visitor";
    } else if (org_id) {
      message = "No recordings found for this organization";
    }

    return (
      <div className="space-y-4">
        <div className="flex justify-end">
          <RecordingsFilter onApplyFilter={handleApplyFilter} />
        </div>
        <div className="py-8 text-center text-gray-500">{message}</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-end">
        <RecordingsFilter onApplyFilter={handleApplyFilter} />
      </div>
      <Table className="hidden bg-white min-[820px]:table">
        <TableHeader>
          <TableRow>
            <TableHead>Visitor</TableHead>
            <TableHead>Organization</TableHead>
            <TableHead>Created At</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {recordings.map((recording) => (
            <TableRow key={recording._id}>
              <TableCell>
                <Link to={`/visitors/${recording.visitor_id}`}>
                  <div className="font-medium">{recording.visitor_name}</div>
                  <div className="text-sm text-muted-foreground">
                    {recording.visitor_id}
                  </div>
                </Link>
              </TableCell>

              <TableCell>
                <div>{recording.org_name}</div>
                <div className="text-sm text-muted-foreground">
                  {recording.org_id}
                </div>
              </TableCell>
              <TableCell>{formatDate(recording.created_at)}</TableCell>
              <TableCell>
                <Link to={`/recordings/${recording._id}`}>
                  <Button
                    variant="outline"
                    size="sm"
                    className="text-white bg-black rounded-lg hover:bg-black/90 hover:text-white"
                  >
                    Watch Recording
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Mobile card view */}
      <div className="space-y-4 min-[820px]:hidden">
        {recordings.map((recording) => (
          <div
            key={recording._id}
            className="p-4 space-y-3 bg-white rounded-lg shadow"
          >
            <div>
              <div className="text-sm text-gray-500">Visitor</div>
              <Link to={`/visitors/${recording.visitor_id}`}>
                <div className="font-medium">{recording.visitor_name}</div>
                <div className="text-sm text-muted-foreground">
                  {recording.visitor_id}
                </div>
              </Link>
            </div>

            <div>
              <div className="text-sm text-gray-500">Organization</div>
              <div className="font-medium">{recording.org_name}</div>
              <div className="text-sm text-muted-foreground">
                {recording.org_id}
              </div>
            </div>

            <div>
              <div className="text-sm text-gray-500">Created At</div>
              <div>{formatDate(recording.created_at)}</div>
            </div>

            <Link to={`/recordings/${recording._id}`} className="block">
              <Button
                variant="outline"
                size="sm"
                className="w-full text-white bg-black rounded-lg hover:bg-black/90 hover:text-white"
              >
                Watch Recording
              </Button>
            </Link>
          </div>
        ))}
      </div>

      <div className="flex justify-end items-center py-4 space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FiChevronLeft className="w-4 h-4" />
        </Button>

        <p className="text-sm text-muted-foreground">
          {currentPage} of {totalPages}
        </p>

        <Button
          variant="outline"
          size="sm"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FiChevronRight className="w-4 h-4" />
        </Button>
      </div>
    </div>
  );
}
