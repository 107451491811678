import { DailyVideo, useLocalSessionId } from "@daily-co/daily-react";

export default function ClientsCamera() {
  const localSessionId = useLocalSessionId();

  return (
    localSessionId && (
      <div className="relative w-full max-w-[800px] border border-gray-200  shadow-lg shadow-gray-200 rounded-md">
        <div className="relative w-full pt-[56.25%] rounded-md">
          <DailyVideo
            type="video"
            sessionId={localSessionId}
            fit="cover"
            className="object-cover absolute top-0 left-0 w-full h-full scale-x-[-1] rounded-md"
          />
        </div>
      </div>
    )
  );
}
