import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn/table";
import ConfirmDeleteCard from "../../ui/ConfirmDeleteCard";
import apiWorkflowActivate from "./WorkflowApis/apiWorkflowActivate";
import apiWorkflowDeactivate from "./WorkflowApis/apiWorkflowDeactivate";
import Spinner from "../../ui/Spinner";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../shadcn/dropdown-menu";
import { Button } from "../../shadcn/button";
import { BsThreeDotsVertical, BsPencil, BsClipboard } from "react-icons/bs";
import { WorkflowContext } from "./WorkflowContextProvider";
import WorkflowPopup from "./WorkflowPopup";
import toast from "react-hot-toast";
import WorkflowToggleSwitch from "./WorkflowToggleSwitch";

export default function WorkflowsTable() {
  const { workflows, fetchWorkflows, isLoading, error } =
    useContext(WorkflowContext);
  const [editingWorkflowId, setEditingWorkflowId] = useState(null);

  const handleToggleSwitch = async (workflowId, isActive) => {
    console.log(workflowId, isActive);
    if (isActive) {
      await apiWorkflowDeactivate(workflowId);
      toast.success("Workflow deactivated.");
    } else {
      await apiWorkflowActivate(workflowId);
      toast.success("Workflow activated.");
    }
    // Fetch workflows again after the toggle action
    fetchWorkflows(); // Ensure this function is accessible in this scope
  };

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleEdit = (workflowId) => {
    setEditingWorkflowId(workflowId);
  };

  const handleClosePopup = () => {
    setEditingWorkflowId(null);
  };

  const handleCopyWorkflowId = async (workflowId) => {
    try {
      await navigator.clipboard.writeText(workflowId);
      toast.success("Workflow ID copied to clipboard");
    } catch (err) {
      console.error("Failed to copy workflow ID: ", err);
      toast.error("Failed to copy workflow ID");
    }
  };

  return (
    <div className="overflow-auto w-full">
      <Table className="bg-transparent sm:bg-white">
        <TableHeader className="hidden sm:table-header-group">
          <TableRow>
            <TableHead className="w-[40%] min-w-[200px]">NAME</TableHead>
            <TableHead className="w-[20%] min-w-[150px]">TYPE</TableHead>
            <TableHead className="w-[20%] min-w-[100px]">
              TIMES TRIGGERED
            </TableHead>
            <TableHead className="w-[10%] min-w-[80px]">STATUS</TableHead>
            <TableHead className="w-[10%] min-w-[80px]">ACTIONS</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {workflows.map((workflow) => (
            <TableRow
              key={workflow._id}
              className="flex flex-col mb-4 bg-white rounded-lg sm:table-row sm:bg-transparent sm:mb-0 hover:bg-white sm:hover:bg-gray-50"
            >
              <TableCell className="text-lg font-semibold sm:text-base sm:font-medium">
                {workflow.name}
              </TableCell>
              <TableCell className="hidden sm:table-cell">
                {workflow.action.type}
              </TableCell>
              <TableCell className="flex gap-4 sm:hidden">
                <p>Workflow type: </p>
                {workflow.action.type}
              </TableCell>
              <TableCell className="hidden sm:table-cell">
                {workflow.times_triggered}
              </TableCell>
              <TableCell className="flex gap-4 sm:hidden">
                <p>Times triggered: </p>
                {workflow.times_triggered}
              </TableCell>

              <TableCell className="hidden sm:table-cell">
                <WorkflowToggleSwitch
                  isOn={workflow.active}
                  workflowId={workflow._id}
                  onToggle={() =>
                    handleToggleSwitch(workflow._id, workflow.active)
                  }
                />
              </TableCell>

              <TableCell className="flex justify-between px-4">
                <div className="block sm:hidden">
                  <WorkflowToggleSwitch
                    isOn={workflow.active}
                    workflowId={workflow._id}
                    onToggle={() =>
                      handleToggleSwitch(workflow._id, workflow.active)
                    }
                  />
                </div>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="p-0 w-8 h-8">
                      <span className="sr-only">Open menu</span>
                      <BsThreeDotsVertical className="w-4 h-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => handleEdit(workflow._id)}>
                      <BsPencil className="mr-2 w-4 h-4" />
                      <span className="text-black">Edit</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="p-0"
                      onSelect={(e) => e.preventDefault()}
                    >
                      <ConfirmDeleteCard
                        workflowName={workflow.name}
                        workflowId={workflow._id}
                      />
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => handleCopyWorkflowId(workflow._id)}
                    >
                      <BsClipboard className="mr-2 w-4 h-4" />
                      <span className="text-black">Copy Workflow ID</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editingWorkflowId && (
        <WorkflowPopup
          workflowId={editingWorkflowId}
          handleCancel={handleClosePopup}
        />
      )}
    </div>
  );
}
