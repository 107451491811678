const apiSlackAgentAction = async (action, visitorId) => {
  const org_id = localStorage.getItem("orgid");
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/front-actions/agent-action`,
      {
        method: "POST",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action_type: action,
          org_id: org_id,
          visitor_id: visitorId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Agent slack action failed");
    }

    const data = await response.json();
    return data; // Return fetched data
  } catch (error) {
    console.error("Agent slack action error  :", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiSlackAgentAction;
