import { DailyVideo, useParticipantIds } from "@daily-co/daily-react";

import React from "react";

export default function VisitorsCamera() {
  const remoteParticipantIds = useParticipantIds({ filter: "remote" });
  return (
    remoteParticipantIds.length > 0 &&
    remoteParticipantIds.map((id) => (
      <React.Fragment key={id}>
        <div className="relative w-full max-w-[800px] border border-gray-200  shadow-lg shadow-gray-200 rounded-md">
          <div className="relative w-full pt-[56.25%] rounded-md">
            <DailyVideo
              type="video"
              sessionId={id}
              fit="cover"
              className="object-cover absolute top-0 left-0 w-full h-full scale-x-[-1] rounded-md"
            />
          </div>
        </div>
      </React.Fragment>
    ))
  );
}
