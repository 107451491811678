import React, { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import VisitorInfoCard from "./VisitorInfoCard";
import { VisitorHistorySessions } from "./VisitorHistorySessions";
import VisitorsIdentityCard from "../modules/Visitors/VisitorsIdentityCard";
import apiVisitorData from "../modules/Visitors/VisitorsApis/apiVisitorData";
import Spinner from "./Spinner";
import { Badge } from "../shadcn/badge";
import { Button } from "../shadcn/button";
import toast from "react-hot-toast";
import { Dialog, DialogContent } from "../shadcn/dialog";
import VisitorNameInput from "./VisitorNameInput";
import { F2FProvider } from "../F2F/F2FContext";
import F2F from "../F2F/F2FComponents/F2F";

const flagemojiToPNG = (countryCode) => {
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode.toLowerCase()}.png`}
      alt="flag"
    />
  );
};

function formatTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  if (h > 0) {
    return `${h} h ${m} min`;
  } else if (m > 0) {
    return `${m} min`;
  } else {
    return `${s} sec`;
  }
}

const createInfoData = (visitorData) => {
  const isIdentified =
    visitorData?.data && Object.keys(visitorData.data).length > 0;

  return [
    {
      title: "User Info",
      status: isIdentified ? "identified" : "not identified",
      _id: visitorData?._id,
      ...(isIdentified ? visitorData.data : {}),
    },
    {
      title: "Browser Info",
      country: (
        <p className="flex gap-2">
          <span>{visitorData?.location.country}</span>
          {flagemojiToPNG(visitorData?.location.country)}
        </p>
      ),
      city: visitorData?.location?.city || "*Montreal",
      ip: visitorData?.ip || "*192.100.921.08",
      devicetype: visitorData?.device?.type || "*Mobile",
      devicename: visitorData?.device?.name || "*iPhone",
      browser: visitorData?.browser?.name || "*Safari",
    },
    {
      title: "History Overview",
      "# OF SESSIONS": visitorData?.total_browsing_sessions,
      "TOTAL DURATION": formatTime(visitorData?.total_browsing_time),
      "LAST LOGIN": visitorData?.last_login
        ? new Date(visitorData.last_login).toLocaleString()
        : "*6d ago",
      "FIRST LOGIN": visitorData?.created_at
        ? new Date(visitorData.created_at).toLocaleString()
        : "*10d ago",
      "# F2F SESSIONS": visitorData?.total_f2f_sessions,
      "TIME SPENT F2F": formatTime(visitorData?.total_f2f_time),
    },
  ];
};

function Container() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [visitorData, setVisitorData] = useState(null);
  const [isIdentityCardOpen, setIsIdentityCardOpen] = useState(false);
  const [visitorIdentityData, setVisitorIdentityData] = useState(
    visitorData?.visitor?.data || {}
  );
  const [searchParams] = useSearchParams();

  const fetchVisitors = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await apiVisitorData(id);

      if (data.visitor.data) {
        setVisitorIdentityData(data.visitor.data);
      }
      setVisitorData(data);
    } catch (error) {
      setError(error.message);
      toast.error(`Error loading visitor data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchVisitors();
  }, [fetchVisitors]);

  useEffect(() => {
    const callRequest = searchParams.get("callRequest");
    if (callRequest === "true" && visitorData?.visitor?.online === false) {
      fetchVisitors();
    }
  }, [searchParams, visitorData, fetchVisitors]);

  const handleNameUpdate = (newName) => {
    setVisitorData((prevData) => ({
      ...prevData,
      visitor: {
        ...prevData.visitor,
        name: newName,
      },
    }));
  };

  const handleIdentityUpdate = (newIdentityData) => {
    const cleanedData = Object.fromEntries(
      Object.entries(newIdentityData).filter(
        ([key, value]) => key.trim() !== "" && value.trim() !== ""
      )
    );

    setVisitorIdentityData(cleanedData);
    setVisitorData((prevData) => ({
      ...prevData,
      visitor: {
        ...prevData.visitor,
        data: cleanedData, // Pass the cleaned data back
      },
    }));
  };

  if (isLoading) {
    return (
      <div className="mt-52">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="pt-5">
      <div className="flex flex-col gap-4 justify-between items-center px-4 py-2 w-full bg-white rounded-lg min-[440px]:flex-row min-[440px]:gap-0">
        <div className="flex justify-between w-full min-[440px]:gap-4 min-[440px]:w-auto min-[440px]:items-center">
          <Badge
            variant={
              visitorData?.visitor?.online === false ? "secondary" : "default"
            }
            className="flex justify-center items-center w-20 text-base"
          >
            {visitorData?.visitor?.online ? "online" : "offline"}
          </Badge>
          <VisitorNameInput
            initialName={visitorData?.visitor?.name || ""}
            visitorId={id}
            onNameUpdate={handleNameUpdate}
          />
        </div>

        <div className="w-full min-[440px]:w-auto">
          <Button
            variant="outline"
            size="sm"
            className="pl-2 mr-2 w-full h-12 text-white bg-black sm:w-auto hover:bg-black/80 hover:border-1 hover:border-gray-100 md:w-48 hover:text-white"
            onClick={() => setIsIdentityCardOpen(true)}
          >
            {"Identity"}
          </Button>
        </div>
      </div>
      <div className="grid  xl:grid-cols-[1fr_20rem] 2xl:grid-cols-[1fr_27rem] gap-x-8 pt-5 sm:grid-cols-1 sm:gap-y-4 gap-y-4">
        <div className="flex flex-col gap-4">
          {visitorData && (
            <F2FProvider visitorData={visitorData}>
              <F2F />
            </F2FProvider>
          )}
          <VisitorHistorySessions id={id} />
          {/* <VisitorHistorySessionsV2 /> */}
        </div>
        <div className="flex flex-col gap-4">
          {visitorData &&
            createInfoData({
              ...visitorData.visitor,
              data: visitorIdentityData,
            }).map((info) => <VisitorInfoCard info={info} key={info.title} />)}
        </div>
      </div>

      <Dialog open={isIdentityCardOpen} onOpenChange={setIsIdentityCardOpen}>
        <DialogContent className="p-0 rounded-lg">
          {visitorData && (
            <VisitorsIdentityCard
              initialData={visitorIdentityData}
              visitor_id={id}
              onIdentityUpdate={handleIdentityUpdate}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Container;
