import WorkflowsTable from "../modules/Workflows/WorkflowsTable";
import { useContext, useEffect, useState } from "react";
import WorkflowPopup from "../modules/Workflows/WorkflowPopup";
import { WorkflowContext } from "../modules/Workflows/WorkflowContextProvider";
import SidebarOpenButton from "../ui/SidebarOpenButton";
function Workflows() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { workflows, fetchWorkflows } = useContext(WorkflowContext);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-bold pointer-events-none">Workflows</p>
          {workflows.length > 0 && <p> {workflows.length} Workflows</p>}
        </div>
        {console.log(workflows)}
        <SidebarOpenButton />
      </div>
      <div className="flex flex-col gap-4 items-end">
        <button
          className="px-5 py-2 font-semibold text-white bg-black rounded-lg"
          onClick={() => setIsPopupOpen(true)}
        >
          New Workflow
        </button>
        <WorkflowsTable />
      </div>
      {isPopupOpen && <WorkflowPopup handleCancel={setIsPopupOpen} />}
    </>
  );
}

export default Workflows;
