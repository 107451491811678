import React, { useState } from "react";
import { Button } from "../../shadcn/button";
import { Clipboard } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";

const OrgReveal = () => {
  const [revealed, setRevealed] = useState(false);
  const orgId = localStorage.getItem("orgid");

  const handleClick = () => {
    if (!revealed) {
      setRevealed(true);
    } else {
      navigator.clipboard
        .writeText(orgId)
        .then(() => {
          toast.success("Org ID copied to your clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
          toast.error("Failed to copy Org ID");
        });
    }
  };

  const truncateApiKey = (key, visibleChars = 20) => {
    return key.slice(0, visibleChars) + "...";
  };

  return (
    <div className="w-full min-[500px]:w-64">
      <Button
        onClick={handleClick}
        className={`
            w-full min-[500px]:w-64 h-10 font-semibold
            transition-all duration-300 ease-in-out
            flex items-center px-4
            ${
              revealed
                ? "justify-between text-black bg-gray-200 hover:bg-gray-200/80"
                : "justify-center text-white bg-black/90 hover:bg-black/80"
            }
          `}
      >
        <span className={revealed ? "":"text-center w-full"}>
          {revealed ? truncateApiKey(orgId) : "Click here to reveal Org ID"}
        </span>
        {revealed && <Clipboard className="ml-2 w-4 h-4" />}
      </Button>
    </div>
  );
};

export default OrgReveal;
