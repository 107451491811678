import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../shadcn/accordion";
import apiVisitorSessions from "../modules/Visitors/VisitorsApis/apiVisitorSessions";
import { Card, CardContent } from "../shadcn/card";
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import { Button } from "../shadcn/button";

// Utility function to format date strings
const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
};

// Utility function to format duration in seconds to a readable format
const formatDuration = (seconds) => {
  if (seconds === undefined || seconds === null) return "N/A";

  try {
    const totalSeconds = parseInt(seconds, 10);
    if (isNaN(totalSeconds)) return seconds;

    if (totalSeconds < 60) {
      return `${totalSeconds} sec`;
    } else if (totalSeconds < 3600) {
      const minutes = Math.floor(totalSeconds / 60);
      const remainingSeconds = totalSeconds % 60;
      return `${minutes} min${
        remainingSeconds > 0 ? ` ${remainingSeconds} sec` : ""
      }`;
    } else {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      return `${hours} hr${hours > 1 ? "s" : ""}${
        minutes > 0 ? ` ${minutes} min` : ""
      }`;
    }
  } catch (error) {
    console.error("Error formatting duration:", error);
    return seconds;
  }
};

// Utility function to format date for accordion header
const formatHeaderDate = (dateString) => {
  if (!dateString) return "N/A";

  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return dateString;
  }
};

export function VisitorHistorySessions({ id }) {
  const [sessionData, setSessionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageData, setNextPageData] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchHistorySessions = async (page) => {
    try {
      setIsLoading(true);
      const data = await apiVisitorSessions(id, page, 3);

      return data.sessions;
    } catch (error) {
      toast.error(`Error loading visitor data: ${error.message}`);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const prefetchNextPage = async (page) => {
    setIsLoadingMore(true);
    const nextData = await fetchHistorySessions(page);
    setNextPageData(nextData);
    setIsLoadingMore(false);
  };

  useEffect(() => {
    const initialFetch = async () => {
      const initialData = await fetchHistorySessions(1);
      setSessionData(initialData);
      prefetchNextPage(2);
    };
    initialFetch();
  }, [id]);

  const handleLoadMore = () => {
    setSessionData((prevData) => [...prevData, ...nextPageData]);
    setCurrentPage((prevPage) => prevPage + 1);
    setNextPageData([]);
    prefetchNextPage(currentPage + 2);
  };

  if (isLoading && sessionData.length === 0) {
    return (
      <div className="mt-52">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-lg">
      <div className="font-medium">Session History</div>
      <Accordion type="single" collapsible className="w-full">
        {sessionData.length > 0 ? (
          sessionData.map((session, index) => (
            <AccordionItem key={index} value={`item-${index + 1}`}>
              <AccordionTrigger className="p-3 mb-2 h-10 bg-gray-100 rounded-lg hover:bg-gray-200">
                {formatHeaderDate(session.start_time)}
              </AccordionTrigger>
              <AccordionContent>
                <Content session={session} />
              </AccordionContent>
            </AccordionItem>
          ))
        ) : (
          <div className="m-4 text-center bg-white rounded-lg">
            There is no history of sessions.
          </div>
        )}
      </Accordion>
      {nextPageData.length > 0 && !isLoadingMore && (
        <div className="flex justify-center mt-4">
          <Button
            onClick={handleLoadMore}
            disabled={isLoading}
            className="px-4 py-2 text-gray-900 bg-gray-200 rounded-lg hover:bg-gray-300"
          >
            Load More...
          </Button>
        </div>
      )}
    </div>
  );
}

function Content({ session }) {
  const {
    attempts_to_f2f,
    end_time,
    pages_visited,
    start_time,
    time_spent_f2f,
    total_time_spent,
  } = session;

  // Utility function to format page URLs for better display
  const formatPageUrl = (url) => {
    if (!url) return "";

    // Remove leading @ symbol if present
    let formattedUrl = url.startsWith("@") ? url.substring(1) : url;

    // Handle URLs with protocol
    if (
      formattedUrl.startsWith("http://") ||
      formattedUrl.startsWith("https://")
    ) {
      // Remove the protocol part
      formattedUrl = formattedUrl.replace(/^https?:\/\//, "");
    }

    // Remove trailing slash if present (except if it's just '/')
    if (formattedUrl !== "/" && formattedUrl.endsWith("/")) {
      formattedUrl = formattedUrl.slice(0, -1);
    }

    // If it's just a slash, return "Homepage"
    if (formattedUrl === "/") return "Homepage";

    return formattedUrl;
  };

  const tableData = [
    { label: "Started", value: formatDate(start_time) },
    { label: "Ended", value: formatDate(end_time) },
    { label: "Duration", value: formatDuration(total_time_spent) },
    { label: "Attempts", value: attempts_to_f2f || 0 },
    { label: "F2F Time", value: formatDuration(time_spent_f2f) },
  ];

  return (
    <Card className="mx-auto w-full border-0 shadow-none">
      <CardContent className="p-2">
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-5">
          {tableData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-1 text-center"
            >
              <div className="mb-1 text-xs text-gray-500">{item.label}</div>
              <div className="text-sm font-medium text-gray-800">
                {item.value}
              </div>
            </div>
          ))}
        </div>

        {pages_visited &&
          pages_visited.length > 0 &&
          !(pages_visited.length === 1 && pages_visited[0] === "/") && (
            <div className="pt-2 mt-2 border-t border-gray-100">
              <div className="mb-1 text-xs text-gray-500">Pages Visited</div>
              <div className="grid grid-cols-1 gap-1 text-sm text-gray-800 sm:grid-cols-2">
                {pages_visited.map((page, index) => (
                  <div key={index} className="truncate">
                    {formatPageUrl(page)}
                  </div>
                ))}
              </div>
            </div>
          )}
      </CardContent>
    </Card>
  );
}
