const apiAllOrgs = async (key) => {
  const apiKey = localStorage.getItem("apikey");
  const userId = localStorage.getItem("userid");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": key || apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Fetching all orgs failed");
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching all orgs:", error);
    throw error;
  }
};

export default apiAllOrgs;
