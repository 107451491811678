import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn/card";
import { ChartContainer, ChartTooltip } from "../../shadcn/chart";
import { useEffect, useState } from "react";
import apiVisitorsTraffic from "./apiVisitorsTraffic";
import Spinner from "../../ui/Spinner";

export default function VisitorsTrafficChart() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRecentWeek, setShowRecentWeek] = useState(true);
  const [allData, setAllData] = useState([]);

  // Function to get day name from date string
  const getDayName = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { weekday: "short" }); // 'short' gives abbreviated day name (Mon, Tue, etc.)
  };

  useEffect(() => {
    const fetchTrafficData = async () => {
      try {
        setLoading(true);
        const trafficData = await apiVisitorsTraffic();
        if (trafficData && trafficData.traffic) {
          // Add day property to each item in the traffic data
          const processedData = trafficData.traffic.map((item) => ({
            ...item,
            day: getDayName(item.date),
          }));
          setAllData(processedData);
          // Initially show the most recent 7 days
          setData(processedData.slice(-7));
        }
      } catch (error) {
        console.error("Failed to fetch visitor traffic data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrafficData();
  }, []);

  // Toggle between recent and previous week
  useEffect(() => {
    if (allData.length > 0) {
      if (showRecentWeek) {
        // Show most recent 7 days
        setData(allData.slice(-7));
      } else {
        // Show previous 7 days
        setData(allData.slice(-14, -7));
      }
    }
  }, [showRecentWeek, allData]);

  const totalVisitors = data.reduce((sum, item) => sum + item.count, 0);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>Visitor Traffic</CardTitle>
        <CardDescription>
          {showRecentWeek ? "This" : "Last"} week's visitors: {totalVisitors}
        </CardDescription>
        <div className="flex items-center mt-2 space-x-2">
          <button
            onClick={() => setShowRecentWeek(false)}
            className={`px-3 py-1 text-sm rounded-md ${
              !showRecentWeek
                ? "bg-primary text-primary-foreground"
                : "bg-muted text-muted-foreground"
            }`}
          >
            Last Week
          </button>
          <button
            onClick={() => setShowRecentWeek(true)}
            className={`px-3 py-1 text-sm rounded-md ${
              showRecentWeek
                ? "bg-primary text-primary-foreground"
                : "bg-muted text-muted-foreground"
            }`}
          >
            This Week
          </button>
        </div>
      </CardHeader>
      <CardContent className="pr-8">
        <ChartContainer
          config={{
            visitors: {
              label: "Visitors",
              color: "hsl(var(--primary))",
            },
          }}
          className="aspect-[4/3] sm:aspect-[16/9] max-h-[400px] w-full overflow-hidden"
        >
          <LineChart
            data={data}
            margin={{
              top: 16,
              right: 30,
              bottom: 24,
              left: 16,
            }}
            responsive={true}
            width={500}
            height={300}
          >
            <CartesianGrid vertical={false} className="stroke-muted" />
            <XAxis
              dataKey="day"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              className="text-sm text-muted-foreground"
              tick={{ fontSize: "0.75rem" }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={12}
              className="text-sm text-muted-foreground"
              tick={{ fontSize: "0.75rem" }}
              allowDecimals={false}
              width={40}
            />
            <ChartTooltip
              content={({ active, payload }) => {
                if (!active || !payload) return null;
                return (
                  <div className="p-2 rounded-lg border shadow-sm bg-background">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex flex-col">
                        <span className="text-[0.70rem] uppercase text-muted-foreground">
                          Day
                        </span>
                        <span className="font-bold text-muted-foreground">
                          {payload[0]?.payload.day}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-[0.70rem] uppercase text-muted-foreground">
                          Visitors
                        </span>
                        <span className="font-bold">{payload[0]?.value}</span>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            <Line
              type="monotone"
              dataKey="count"
              stroke="hsl(var(--primary))"
              strokeWidth={2}
              dot={{
                r: 3,
                fill: "hsl(var(--primary))",
                strokeWidth: 0,
              }}
              activeDot={{
                r: 4,
                fill: "hsl(var(--primary))",
                stroke: "hsl(var(--background))",
                strokeWidth: 2,
              }}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
