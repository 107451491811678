import { RecordingsTable } from "../modules/RecordingPage/RecordingsTable";
import SidebarOpenButton from "../ui/SidebarOpenButton";

const RecordingsRRWPage = () => {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Session Recordings</h1>
        </div>
        <SidebarOpenButton />
      </div>

      <RecordingsTable />
    </div>
  );
};

export default RecordingsRRWPage;
