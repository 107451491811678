const apiAllRecordings = async ({ page, batch_size, org_id, visitor_id }) => {
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  let url = `${process.env.REACT_APP_API_URL}/rrweb/recordings?page=${page}&batch_size=${batch_size}`;

  if (org_id) {
    url += `&org_id=${org_id}`;
  }
  if (visitor_id) {
    url += `&visitor_id=${visitor_id}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "X-User-ID": userId,
        "X-API-Key": apiKey,
      },
    });

    if (!response.ok) {
      throw new Error("Fetching all recordings failed");
    }

    const data = await response.json();

    return data; // Return fetched data
  } catch (error) {
    console.error("Error fetching all recordings:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiAllRecordings;
