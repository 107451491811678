import { useContext, useMemo, useEffect, useState } from "react";
import Logo from "./Logo";
import MainNav from "./MainNav";
import { AppLayoutContext } from "../layouts/AppLayout";
import { IntercomProvider } from "react-use-intercom";
import IntercomButton from "./IntercomButton";

function Sidebar() {
  const { sidebarOpen, setSidebarOpen, sidebarHovered, setSidebarHovered } =
    useContext(AppLayoutContext);
  const [isMobile, setIsMobile] = useState(false);

  // Check if we're on mobile based on screen width
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 1024); // 1024px is the lg breakpoint in Tailwind
    };

    // Initial check
    checkIfMobile();

    // Add event listener for window resize
    window.addEventListener("resize", checkIfMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  // Calculate if sidebar is expanded based on context values
  const isExpanded = useMemo(() => {
    // On mobile, only expand if sidebarOpen is true
    if (isMobile) {
      return sidebarOpen;
    }
    // On desktop, expand on hover or if explicitly opened
    return sidebarHovered || sidebarOpen;
  }, [sidebarHovered, sidebarOpen, isMobile]);

  // Handle mouse events only on desktop
  const handleMouseEnter = () => {
    if (!isMobile) {
      setSidebarHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setSidebarHovered(false);
    }
  };

  return (
    <IntercomProvider
      appId="uyosalr2"
      autoBoot={true}
      shouldInitialize={true}
      initializeDelay={0}
    >
      {/* Mobile sidebar overlay */}
      <div
        className={`
          fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out backdrop-blur-sm z-40 lg:hidden
          ${
            sidebarOpen
              ? "visible opacity-100"
              : "invisible opacity-0 pointer-events-none"
          }
        `}
        onClick={() => setSidebarOpen(false)}
      />

      {/* Sidebar component */}
      <div
        className={`
          bg-white shadow-lg transition-all duration-300 ease-in-out
          fixed top-0 left-0 h-full z-50
          ${
            isMobile
              ? `${sidebarOpen ? "w-60" : "w-0 -translate-x-full"}`:`relative z-auto ${isExpanded ? "w-60" : "w-20"}`}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`flex overflow-hidden flex-col justify-between p-4 h-full ${
            !sidebarOpen && isMobile ? "hidden" : ""
          }`}
        >
          <div>
            <div className="flex justify-between items-center mb-8 ml-[5px]">
              <Logo small={!isExpanded} />
            </div>
            <MainNav expanded={isExpanded} />
          </div>
          <div className="flex flex-col gap-4">
            <IntercomButton
              buttonClass={`
                flex items-center w-full h-14 text-black bg-white rounded-lg hover:bg-black hover:text-white rounded-lg
                ${isExpanded ? "justify-start px-5" : "justify-center"}
              `}
              expanded={isExpanded}
            />
          </div>
        </div>
      </div>
    </IntercomProvider>
  );
}

export default Sidebar;
