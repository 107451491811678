import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../../lib/utils";
import { Button } from "../../../shadcn/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../shadcn/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../shadcn/popover";

export function Step3SearchDropDownList({
  value,
  onValueChange,
  options,
  className,
}) {
  const [open, setOpen] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState("all");
  const commandListRef = React.useRef(null);

  // Helper function to format field names
  const formatFieldName = (key) => {
    return key
      .replace("visitor.", "")
      .split(".")
      .map((part) =>
        part
          .replace(/_/g, " ")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      )
      .join(" › ");
  };

  // Categorize and format items
  const categorizedItems = Object.entries(options)
    .filter(([_, type]) => type !== "object")
    .reduce(
      (acc, [key, type]) => {
        const formattedLabel = formatFieldName(key);
        const item = {
          value: key,
          label: formattedLabel,
          type: type,
        };

        if (key.includes("total") || key.includes("sessions")) {
          acc.statistics.push(item);
        } else if (key.includes("browser") || key.includes("device")) {
          acc.device.push(item);
        } else if (key.includes("location") || key.includes("timezone")) {
          acc.location.push(item);
        } else {
          acc.general.push(item);
        }

        return acc;
      },
      {
        general: [],
        statistics: [],
        device: [],
        location: [],
      }
    );

  // Get all items as a flat array
  const getAllItems = () => {
    return Object.values(categorizedItems).flat();
  };

  // Get filtered items based on active filter
  const getFilteredItems = () => {
    if (activeFilter === "all") return getAllItems();
    return categorizedItems[activeFilter] || [];
  };

  // Add this helper function
  const scrollToTop = () => {
    if (commandListRef.current) {
      commandListRef.current.scrollTop = 0;
    }
  };

  return (
    <div className={cn("pr-2 w-60", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between w-[160px] truncate"
          >
            <span className="truncate">
              {value ? `${value} ` : "Select a field"}
            </span>
            <ChevronsUpDown className="ml-2 w-4 h-4 opacity-50 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 ml-28 w-[400px]">
          <Command>
            <div className="flex gap-1 p-2 border-b">
              <Button
                variant={activeFilter === "all" ? "default" : "outline"}
                size="sm"
                onClick={() => {
                  setActiveFilter("all");
                  scrollToTop();
                }}
                className="text-xs"
              >
                All
              </Button>
              <Button
                variant={activeFilter === "general" ? "default" : "outline"}
                size="sm"
                onClick={() => {
                  setActiveFilter("general");
                  scrollToTop();
                }}
                className="text-xs"
              >
                General
              </Button>
              <Button
                variant={activeFilter === "statistics" ? "default" : "outline"}
                size="sm"
                onClick={() => {
                  setActiveFilter("statistics");
                  scrollToTop();
                }}
                className="text-xs"
              >
                Statistics
              </Button>
              <Button
                variant={activeFilter === "device" ? "default" : "outline"}
                size="sm"
                onClick={() => {
                  setActiveFilter("device");
                  scrollToTop();
                }}
                className="text-xs"
              >
                Device
              </Button>
              <Button
                variant={activeFilter === "location" ? "default" : "outline"}
                size="sm"
                onClick={() => {
                  setActiveFilter("location");
                  scrollToTop();
                }}
                className="text-xs"
              >
                Location
              </Button>
            </div>
            <CommandInput
              placeholder="Search fields..."
              onValueChange={() => scrollToTop()}
            />
            <CommandList ref={commandListRef}>
              <CommandEmpty>No field found.</CommandEmpty>
              {getFilteredItems().map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue) => {
                    onValueChange(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === item.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <div className="flex flex-col">
                    <span className="font-medium">{item.label}</span>
                    <span className="text-xs text-gray-500">{item.value}</span>
                  </div>
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
