import { useEffect, useState, memo } from "react";
import { useIntercom } from "react-use-intercom";
import { GrContact } from "react-icons/gr";
import apiOrganization from "../modules/Dashborad/apiOrganization";

// Memoize the component to prevent unnecessary re-renders
const IntercomButton = memo(function IntercomButton({ buttonClass, expanded }) {
  const { boot, show, hide, update } = useIntercom();
  const [orgData, setOrgData] = useState();

  // Fetch organization data once on component mount
  useEffect(() => {
    let isMounted = true;

    async function fetchOrgData() {
      try {
        const data = await apiOrganization();
        if (isMounted) {
          setOrgData(data.organization);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchOrgData();

    // Cleanup function to prevent state updates on unmounted component
    return () => {
      isMounted = false;
    };
  }, []);

  // Boot and configure Intercom when org data is available
  useEffect(() => {
    if (!orgData) return;

    const userId = localStorage.getItem("userid");
    const agentName = localStorage.getItem("agentName");
    const agentEmail = localStorage.getItem("email");
    const user_created_at = localStorage.getItem("user_created_at");

    // Boot Intercom on initial load
    boot({
      userId,
      name: `${agentName} - ${orgData.name}`,
      email: agentEmail,
      createdAt: user_created_at,
      hideDefaultLauncher: true,
      ...orgData, // Spread the orgData here
    });

    if (window.clarity) {
      window.clarity("set", "user", {
        userId,
        name: `${agentName} - ${orgData.name}`,
        email: agentEmail,
        createdAt: user_created_at,
        hideDefaultLauncher: true,
        ...orgData,
      });
    }

    // Hide the Intercom messenger after booting
    hide();

    // Update Intercom settings to hide the default launcher
    update({ hideDefaultLauncher: true });
  }, [boot, hide, update, orgData]);

  const toggleIntercom = () => {
    show();
  };

  return (
    <button className={buttonClass} onClick={toggleIntercom}>
      <span className="flex flex-shrink-0 justify-center items-center w-5 h-5">
        <GrContact className="w-5 h-5" />
      </span>
      {expanded && (
        <span className="ml-3 text-lg whitespace-nowrap transition-all duration-300">
          Contact us
        </span>
      )}
    </button>
  );
});

export default IntercomButton;
