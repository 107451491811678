const apiSingleRecording = async (recordingId) => {
  const userId = localStorage.getItem("userid");
  const apiKey = localStorage.getItem("apikey");

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/rrweb/recordings/${recordingId}`,
      {
        method: "GET",
        headers: {
          "X-User-ID": userId,
          "X-API-Key": apiKey,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Fetching single recording failed");
    }

    const data = await response.json();
    console.log(data);
    return data; // Return fetched data
  } catch (error) {
    console.error("Error fetching single recording:", error);
    throw error; // Propagate the error to be handled by the caller
  }
};

export default apiSingleRecording;
