import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import VisitorsTable from "../modules/Visitors/VisitorsTable";
import { Dialog, DialogContent, DialogTitle } from "../shadcn/dialog";
import VisitorsFiltersCard from "../modules/Visitors/VisitorsFiltersCard";
import Spinner from "../ui/Spinner";
import apiVisitors from "../modules/Visitors/VisitorsApis/apiVisitors";
import SidebarOpenButton from "../ui/SidebarOpenButton";

function Visitors() {
  const [isFilteringCardOpen, setIsFilteringCardOpen] = useState(false);

  const {
    data: totalVisitorsData,
    isLoading,
    error,
  } = useQuery(["totalVisitors"], () => apiVisitors(1, 1), {
    refetchInterval: 5000, // Refetch every 5 seconds
    staleTime: 3000, // Consider data stale after 3 seconds
  });

  const totalVisitors = totalVisitorsData?.total_visitors;

  if (isLoading) return <Spinner />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div>
          <p className="text-2xl font-bold pointer-events-none">Visitors</p>
          {totalVisitors && <p> {totalVisitors} Visitors</p>}
        </div>

        <SidebarOpenButton />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-end">
          <button
            className="px-5 py-2 font-semibold text-white bg-black rounded-lg"
            onClick={() => setIsFilteringCardOpen(true)}
          >
            Filters
          </button>
        </div>
        <VisitorsTable />
      </div>

      <Dialog open={isFilteringCardOpen} onOpenChange={setIsFilteringCardOpen}>
        <DialogContent className="p-0 rounded-lg">
          <DialogTitle className="hidden">Dialog</DialogTitle>
          <VisitorsFiltersCard onClose={() => setIsFilteringCardOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Visitors;
