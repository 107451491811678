import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../shadcn/dialog";
import { Button } from "../shadcn/button";
import { Input } from "../shadcn/input";
import { Label } from "../shadcn/label";
import toast from "react-hot-toast";
import apiInvitationToOrg from "../Signup/apiInvitationToOrg";

export default function InviteMemberPopup({ setMembers }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [inviteLink, setInviteLink] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      const response = await apiInvitationToOrg(email);
      const link = response.invitation_id;
      setInviteLink("https://app.face2face.io/invitation/" + link);
      setMembers((prevMembers) => [
        ...prevMembers,
        {
          email,
          name,
          inviteId: "https://app.face2face.io/invitation/" + link,
          role: "invited",
        },
      ]);
    } catch (error) {
      console.error("Error generating invitation link:", error);
      toast.error("Failed to generate invitation link. Please try again.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        toast.success("Invite link copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy invite link to clipboard!");
        console.error("Failed to copy: ", err);
      });

    setTimeout(() => {
      setIsOpen(false);
      setEmail("");
      setName("");
      setInviteLink("");
    }, 3000);
  };

  const handleOpenChange = (open) => {
    setIsOpen(open);
    if (!open) {
      // Reset form data when the dialog is closed
      setEmail("");
      setName("");
      setInviteLink("");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="text-white bg-black hover:bg-primary/90 hover:text-white"
        >
          Invite New Member
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] focus:ring-0">
        <DialogHeader>
          <DialogTitle>Invite New Member</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="Enter member's email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mt-2 space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              placeholder="Enter member's name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          {!inviteLink && (
            <Button type="submit" className="mt-4 w-full">
              Generate Invite Link
            </Button>
          )}
        </form>

        {inviteLink && (
          <Button
            onClick={copyToClipboard}
            className="w-full text-black bg-green-400 hover:bg-green-300"
          >
            Click here to copy invite link
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}
