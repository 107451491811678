import { useSearchParams } from "react-router-dom";

const PopupTab = () => {
  const [searchParams] = useSearchParams();
  const editPopup = searchParams.get("editpopup");
  const newpopup = searchParams.get("newpopup");
  const org_id = localStorage.getItem("orgid");
  const agent_id = localStorage.getItem("userid");
  const isAdmin = localStorage.getItem("isOwner");

  return (
    <div className="flex overflow-auto justify-start h-full">
      <div className="w-full h-auto">
        <div className="relative w-full h-full">
          <iframe
            title="waitlist"
            src={`https://app2.face2face.io/popup_config?orgId=${org_id}&agentId=${agent_id}&admin=${isAdmin}&debug_mode=true&env=${
              process.env.DEPLOY_STAGE
            }${editPopup ? `&editpopup=${editPopup}` : ""}${
              newpopup ? "&newpopup=true" : ""
            }`}
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default PopupTab;
