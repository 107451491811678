import { RxHamburgerMenu } from "react-icons/rx";
import { AppLayoutContext } from "../layouts/AppLayout";
import { useContext } from "react";

export default function SidebarOpenButton() {
  const { setSidebarOpen } = useContext(AppLayoutContext);
  return (
    <button
      className="flex justify-center items-center w-10 h-10 text-black bg-white rounded-full border border-transparent hover:border-black active:border-black lg:hidden"
      onClick={() => setSidebarOpen((sidebarOpen) => !sidebarOpen)}
    >
      <RxHamburgerMenu />
    </button>
  );
}
