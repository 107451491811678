import { useCallback, useState } from "react";
import { Button } from "../../../shadcn/button";
import { Input } from "../../../shadcn/input";
import { Label } from "../../../shadcn/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../shadcn/card";
import apiUpdateLogedUser from "../../../Signup/apiUpdateLogedUser";
import toast from "react-hot-toast";

const AccountTab = () => {
  const [name, setName] = useState(localStorage.getItem("agentName") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [initialName, setInitialName] = useState(name);
  const [initialEmail, setInitialEmail] = useState(email);

  const handleNameChange = useCallback((e) => setName(e.target.value), []);
  const handleEmailChange = useCallback((e) => setEmail(e.target.value), []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
    setShowRepeatPassword(e.target.value.length > 0);
  }, []);

  const handleRepeatPasswordChange = useCallback(
    (e) => {
      setRepeatPassword(e.target.value);
      setPasswordError(
        e.target.value !== password ? "Passwords do not match" : ""
      );
    },
    [password]
  );

  const handleSubmit = async () => {
    if (password !== repeatPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    const payload = {};
    let hasChanges = false;

    if (name !== initialName) {
      payload.name = name;
      hasChanges = true;
    }
    if (email !== initialEmail) {
      payload.email = email;
      hasChanges = true;
    }
    if (password) {
      payload.password = password;
      hasChanges = true;
    }

    if (!hasChanges) {
      toast.error("No changes detected");
      return;
    }

    // Only make API call if there are changes
    try {
      await apiUpdateLogedUser(payload);

      // Update local storage only after successful API call
      if (payload.name) localStorage.setItem("agentName", payload.name);
      if (payload.email) localStorage.setItem("email", payload.email);

      // Update initial values
      setInitialName(name);
      setInitialEmail(email);

      // Clear password fields
      setPassword("");
      setRepeatPassword("");
      setShowRepeatPassword(false);

      toast.success("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to save changes. Please try again.");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Account Settings</CardTitle>
          <CardDescription>Manage your account details here.</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">Full Name</Label>
            <Input
              id="name"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter your full name"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter new password"
            />
          </div>
          {showRepeatPassword && (
            <div className="space-y-2">
              <Label htmlFor="repeatPassword">Repeat Password</Label>
              <Input
                id="repeatPassword"
                type="password"
                value={repeatPassword}
                onChange={handleRepeatPasswordChange}
                placeholder="Repeat new password"
              />
              {passwordError && (
                <p className="text-sm text-red-500">{passwordError}</p>
              )}
            </div>
          )}
        </CardContent>
        <CardFooter>
          <Button onClick={handleSubmit}>Save Changes</Button>
        </CardFooter>
      </Card>

      <Button
        className="mt-10 w-full sm:w-[137px] sm:ml-6"
        variant="destructive"
        onClick={handleLogout}
      >
        Logout
      </Button>
    </>
  );
};

export default AccountTab;
