import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "../../shadcn/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../../shadcn/card";
import { Label } from "../../shadcn/label";
import { RadioGroup, RadioGroupItem } from "../../shadcn/radio-group";
import { Textarea } from "../../shadcn/textarea";
import toast from "react-hot-toast";
import apiCallRating from "../F2FApis/apiCallRating";

export default function CallRating({ onComplete, visitorId }) {
  const [rating, setRating] = useState("");
  const [feedback, setFeedback] = useState("");
  const [step, setStep] = useState("rating");
  const [hoveredRating, setHoveredRating] = useState(null);

  const getRatingText = (value) => {
    if (!value) return "Select your rating";
    const texts = ["Poor", "Fair", "Good", "Very Good", "Excellent"];
    return texts[value - 1];
  };

  const getEmoji = (value) => {
    const emojis = ["😞", "😕", "😊", "😃", "😍"];
    return emojis[value - 1];
  };

  const handleRatingClick = (value) => {
    setRating(value);
    if (Number.parseInt(value) < 5) {
      setStep("feedback");
    } else {
      handleSubmit(value);
    }
  };

  const handleSubmit = async (ratingValue) => {
    try {
      const finalRating =
        typeof ratingValue === "object" ? rating : ratingValue || rating;
      await apiCallRating(visitorId, finalRating, feedback);
      toast.success("Thank you for your feedback!");
    } catch (error) {
      toast.error("Failed to submit feedback. Please try again.");
      console.error("Error submitting call rating:", error);
    } finally {
      setRating("");
      setFeedback("");
      setStep("rating");
      setHoveredRating(null);
      onComplete();
    }
  };

  return (
    <div className="flex fixed inset-0 z-50 justify-center items-center p-4 backdrop-blur-sm bg-black/70">
      <Card className="w-full max-w-md">
        <AnimatePresence mode="wait">
          {step === "rating" ? (
            <motion.div
              key="rating"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              <CardHeader className="space-y-4">
                <div className="space-y-2">
                  <CardTitle className="text-2xl font-semibold text-center">
                    Rate this call
                  </CardTitle>
                  <CardDescription className="text-sm text-center">
                    Share your experience by selecting a rating
                  </CardDescription>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-6">
                  <RadioGroup
                    value={rating}
                    onValueChange={setRating}
                    className="flex gap-2 justify-center py-4 sm:gap-4 sm:py-6"
                  >
                    {[1, 2, 3, 4, 5].map((value) => (
                      <div
                        key={value}
                        onMouseEnter={() => setHoveredRating(value)}
                        onMouseLeave={() => setHoveredRating(null)}
                        className="relative"
                      >
                        <RadioGroupItem
                          value={value.toString()}
                          id={`rating-${value}`}
                          className="sr-only peer"
                        />
                        <Label
                          htmlFor={`rating-${value}`}
                          className="flex items-center cursor-pointer"
                          onClick={() => handleRatingClick(value.toString())}
                        >
                          <motion.div
                            whileHover={{ scale: 1.2 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                            className="flex justify-center items-center w-10 h-10 rounded-full sm:w-14 sm:h-14 md:w-16 md:h-16"
                          >
                            <span className="text-2xl sm:text-3xl md:text-4xl">
                              {getEmoji(value)}
                            </span>
                          </motion.div>
                        </Label>
                      </div>
                    ))}
                  </RadioGroup>
                  <motion.p
                    className="text-sm font-medium text-center text-muted-foreground"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    key={hoveredRating || "default"}
                  >
                    {getRatingText(
                      hoveredRating || Number.parseInt(rating || "0")
                    )}
                  </motion.p>
                </div>
              </CardContent>
            </motion.div>
          ) : (
            <motion.div
              key="feedback"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold text-center">
                  Tell us more about your experience
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="py-4 space-y-4">
                  <Textarea
                    id="feedback"
                    placeholder="Your feedback helps us improve our service..."
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className="min-h-[150px] resize-none"
                    autoFocus
                  />
                </div>
              </CardContent>
              <CardFooter className="flex gap-2 justify-end">
                <Button variant="outline" onClick={() => setStep("rating")}>
                  Back
                </Button>
                <Button onClick={() => handleSubmit()}>Submit</Button>
              </CardFooter>
            </motion.div>
          )}
        </AnimatePresence>
      </Card>
    </div>
  );
}
