import { useEffect, useState } from "react";
import { Button } from "../../shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../shadcn/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../shadcn/select";
import { Input } from "../../shadcn/input";
import apiAllOrgs from "../../SuperAdmin/apiAllOrgs";

export function RecordingsFilter({ onApplyFilter }) {
  const [open, setOpen] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [visitorId, setVisitorId] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const superAdminKey = localStorage.getItem("adminkey");

  useEffect(() => {
    // Get orgId and visitorId from URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const urlOrgId = urlParams.get("org_id");
    const urlVisitorId = urlParams.get("visitor_id");

    if (urlOrgId) {
      setOrgId(urlOrgId);
    }
    if (urlVisitorId) {
      setVisitorId(urlVisitorId);
    }

    const fetchOrganizations = async () => {
      try {
        const allOrgs = await apiAllOrgs(superAdminKey);
        const validOrgs = allOrgs.organizations.filter(
          (org) => org.name && org._id
        );
        setOrganizations(validOrgs);
      } catch (error) {
        console.error("Failed to fetch organizations:", error);
      }
    };

    fetchOrganizations();
  }, []);

  const handleApply = () => {
    onApplyFilter(orgId, visitorId);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="px-5 py-2 font-semibold text-white bg-black rounded-lg hover:bg-black/90 hover:text-white"
        >
          Filters
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Filter Recordings</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <label htmlFor="org">Organization</label>
            <Select onValueChange={setOrgId} value={orgId}>
              <SelectTrigger className="focus:ring-0 focus:ring-offset-0">
                <SelectValue placeholder="Select organization" />
              </SelectTrigger>
              <SelectContent>
                {organizations.map((org) => (
                  <SelectItem key={org._id} value={org._id}>
                    {org.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-2">
            <label htmlFor="visitorId">Visitor ID</label>
            <Input
              id="visitorId"
              value={visitorId}
              onChange={(e) => setVisitorId(e.target.value)}
              className="border-gray-300 ring-0 ring-offset-0 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:border-gray-300 focus-visible:ring-0 focus-visible:ring-offset-0"
            />
          </div>
        </div>
        <Button onClick={handleApply}>Apply</Button>
      </DialogContent>
    </Dialog>
  );
}
