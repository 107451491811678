import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../shadcn/tabs";
import { FaUsers, FaClock, FaVideo, FaEye } from "react-icons/fa";
import apiOrganization from "../modules/Dashborad/apiOrganization";
import Spinner from "../ui/Spinner";
import VisitorsTrafficChart from "../modules/Dashborad/VisitorsTrafficChart";
import SidebarOpenButton from "../ui/SidebarOpenButton";

function Metric({ label, value }) {
  return (
    <div className="flex flex-col">
      <p className="text-sm font-medium text-muted-foreground">{label}</p>
      <p className="text-2xl font-bold">{value}</p>
    </div>
  );
}

function formatTime(seconds) {
  const h = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const m = Math.floor((seconds % 3600) / 60); // remaining minutes
  const s = seconds % 60; // remaining seconds

  if (h > 0) {
    return `${h} h ${m} min`;
  } else if (m > 0) {
    return `${m} min`;
  } else {
    return `${s} sec`;
  }
}

function calculateAvgSessionTime(totalTimeInSeconds = 0, numOfSessions = 0) {
  if (numOfSessions === 0) return "0 sec"; // Handle divide by zero
  const avgSeconds = Math.round(totalTimeInSeconds / numOfSessions); // Round to nearest whole number

  return formatTime(avgSeconds);
}

function calculateStalkingPercentage(totalSessionSeconds, stalkedSeconds) {
  if (totalSessionSeconds <= 0) {
    return 0;
  }

  if (stalkedSeconds < 0 || stalkedSeconds > totalSessionSeconds) {
    return 0;
  }

  const percentage = (stalkedSeconds / totalSessionSeconds) * 100;
  return Number(percentage.toFixed(1));
}

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [orgData, setOrgData] = useState();
  const logedUser = localStorage.getItem("userid");
  useEffect(() => {
    async function fetchOrgData() {
      try {
        setIsLoading(true);
        const data = await apiOrganization();

        setOrgData(data.organization);

        localStorage.setItem("orgName", data.organization.name);
        // Check if the logged user is an owner
        const userMember = data.organization.members.find(
          (member) => member.user_id === logedUser
        );
        if (userMember && userMember.role === "owner") {
          localStorage.setItem("isOwner", "true");
        } else {
          localStorage.setItem("isOwner", "false");
        }

        if (data.organization?.integrations.slack) {
          localStorage.setItem("slackIntegrated", true);
        } else {
          localStorage.setItem("slackIntegrated", false);
        }
        if (data.organization?.integrations.discord) {
          localStorage.setItem("discordIntegrated", true);
        } else {
          localStorage.setItem("discordIntegrated", false);
        }

        if (data.organization?.integrations.teams) {
          localStorage.setItem("teamsIntegrated", true);
        } else {
          localStorage.setItem("teamsIntegrated", false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchOrgData();
  }, []);

  if (isLoading) return <Spinner />;
  else {
    if (orgData)
      return (
        <div className="text-black bg-gray-100 rounded-lg">
          <div className="flex justify-between">
            <h1 className="pointer-events-none mb-8 w-[250px] min-[610px]:w-[400px] text-4xl font-bold ">
              Customer Dashboard
            </h1>

            <SidebarOpenButton />
          </div>

          <Tabs defaultValue="visitors" className="space-y-6">
            <TabsList className="grid grid-cols-2 gap-4 w-full h-auto bg-gray-100 md:grid-cols-4">
              <TabsTrigger
                value="visitors"
                className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
              >
                <FaUsers className="mb-2 w-6 h-6" />
                <span>Traffic</span>
              </TabsTrigger>
              <TabsTrigger
                value="sessions"
                className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
              >
                <FaClock className="mb-2 w-6 h-6" />
                <span>Sessions</span>
              </TabsTrigger>
              <TabsTrigger
                value="f2f"
                className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
              >
                <FaVideo className="mb-2 w-6 h-6" />
                <span>F2F</span>
              </TabsTrigger>
              <TabsTrigger
                value="stalking"
                className="flex flex-col justify-center items-center py-4 h-24 bg-white hover:bg-gray-50"
              >
                <FaEye className="mb-2 w-6 h-6" />
                <span>Stalking</span>
              </TabsTrigger>
            </TabsList>

            <TabsContent value="visitors">
              <Card>
                <CardHeader>
                  <CardTitle>Visitor Information</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-4">
                    <Metric
                      label="Number of Visitors"
                      value={orgData.total_visitors || "0"}
                    />
                    <Metric
                      label="Identified Visitors"
                      value={orgData.total_identified_visitors}
                    />
                  </div>
                </CardContent>
              </Card>

              <VisitorsTrafficChart />
            </TabsContent>

            <TabsContent value="sessions">
              <Card>
                <CardHeader>
                  <CardTitle>Session Information</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-4">
                    <Metric
                      label="Total Sessions"
                      value={orgData.total_browsing_sessions || "0"}
                    />
                    <Metric
                      label="Total Session Time"
                      value={formatTime(orgData.total_browsing_time || "0")}
                    />
                    <Metric
                      label="Avg. Session Time"
                      value={
                        calculateAvgSessionTime(
                          orgData.total_browsing_time,
                          orgData.total_browsing_sessions
                        ) || "0"
                      }
                    />
                  </div>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="f2f">
              <div className="flex flex-col gap-4">
                <Card>
                  <CardHeader>
                    <CardTitle>Inbound Calls</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-4">
                      <Metric
                        label="Total call requests "
                        value={
                          orgData.inbound.total_inbound_f2f_requests || "0"
                        }
                      />
                      <Metric
                        label="Total answered calls "
                        value={
                          orgData.inbound.total_inbound_f2f_accepted || "0"
                        }
                      />
                      <Metric
                        label="Time spent f2f "
                        value={
                          formatTime(orgData.inbound.total_f2f_time) || "0"
                        }
                      />
                      <Metric
                        label="Avg. length of call "
                        value={
                          calculateAvgSessionTime(
                            orgData.inbound.total_f2f_time,
                            orgData.inbound.total_f2f_sessions
                          ) || "0"
                        }
                      />
                      <Metric
                        label="% calls answered "
                        value={`${(
                          (orgData.inbound.total_inbound_f2f_accepted /
                            orgData.inbound.total_inbound_f2f_requests) *
                            100 || 0
                        ).toFixed(0)}%`}
                      />
                    </div>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle>Outbound Calls</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-4">
                      <Metric
                        label="Total F2F attempts "
                        value={
                          orgData.outbound.total_outbound_f2f_requests || "0"
                        }
                      />
                      <Metric
                        label="Total answered calls "
                        value={
                          orgData.outbound.total_outbound_f2f_accepted || "0"
                        }
                      />
                      <Metric
                        label="Time spent f2f "
                        value={
                          formatTime(orgData.outbound.total_f2f_time) || "0"
                        }
                      />
                      <Metric
                        label="Avg. length of call "
                        value={
                          calculateAvgSessionTime(
                            orgData.outbound.total_f2f_time,
                            orgData.outbound.total_f2f_sessions
                          ) || "0"
                        }
                      />
                      <Metric
                        label="% calls answered "
                        value={`${(
                          (orgData.outbound.total_outbound_f2f_accepted /
                            orgData.outbound.total_outbound_f2f_requests) *
                            100 || 0
                        ).toFixed(0)}%`}
                      />
                    </div>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle>Total F2F Usage </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid gap-4">
                      <Metric
                        label="Total time spent F2F "
                        value={formatTime(orgData.total_f2f_time) || "0"}
                      />
                      <Metric
                        label="Total F2F sessions"
                        value={orgData.total_f2f_sessions || "0"}
                      />
                      <Metric
                        label="Total agent screenshare time "
                        value={
                          formatTime(orgData.total_agent_screenshare_time) ||
                          "0"
                        }
                      />
                      <Metric
                        label="Total agent screenshares"
                        value={orgData.total_agent_screenshares || "0"}
                      />
                      <Metric
                        label="Total audio only sessions"
                        value={orgData.total_audio_only || "0"}
                      />

                      <Metric
                        label="Total audio video sessions"
                        value={orgData.total_audio_video || "0"}
                      />
                      <Metric
                        label="Total visitor screenshare time "
                        value={
                          formatTime(orgData.total_visitor_screenshare_time) ||
                          "0"
                        }
                      />
                      <Metric
                        label="Total visitor screenshares"
                        value={orgData.total_visitor_screenshares || "0"}
                      />
                    </div>
                  </CardContent>
                </Card>
              </div>
            </TabsContent>

            <TabsContent value="stalking">
              <Card>
                <CardHeader>
                  <CardTitle>Stalking Information</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-4">
                    <Metric
                      label="Total Stalking Sessions"
                      value={orgData.total_stalking_sessions || "0"}
                    />
                    <Metric
                      label="Total Stalking Time"
                      value={formatTime(orgData.total_stalking_time || "0")}
                    />
                    <Metric
                      label="Avg. Stalking Time"
                      value={calculateAvgSessionTime(
                        orgData.total_stalking_time,
                        orgData.total_stalking_sessions
                      )}
                    />
                    <Metric
                      label="% Sessions Stalked"
                      value={calculateStalkingPercentage(
                        orgData.total_browsing_time,
                        orgData.total_stalking_time
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      );
  }
}
